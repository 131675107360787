import { render, staticRenderFns } from "./KolVidLanding.vue?vue&type=template&id=f8aab47a&scoped=true&"
import script from "./KolVidLanding.vue?vue&type=script&lang=js&"
export * from "./KolVidLanding.vue?vue&type=script&lang=js&"
import style0 from "./KolVidLanding.vue?vue&type=style&index=0&id=f8aab47a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8aab47a",
  null
  
)

export default component.exports