<template>
  <div>
    <div id="desktop-header" class="container d-none d-lg-block">
      <div class="title">
        {{$t("application.application")}}
      </div>
    </div>
    <div id="mobile-header" class="d-block d-lg-none">
      <div class="title">
        {{$t("application.application")}}
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div id="content">
        <div class="normal-text d-block d-lg-none">
            {{$t("application.introduction")}}
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 col-custom">
                <img src="../assets/images/methods_2024.png" id="method-1" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-8 col-custom">
                <div class="normal-text d-none d-lg-block" id="special-text">
                    {{$t("application.introduction")}}
                </div>
                <div class="subtitle" style="margin-top: 24px; text-align: left;">
                    {{$t("application.aboutTitle")}}
                </div>
                <div class="normal-text" style="margin-top: 5px;">
                    <ul>
                        <li>{{$t("application.about1")}}</li>
                        <li>{{$t("application.about2")}}</li>
                        <li>{{$t("application.about3")}}</li>
                        <li>{{$t("application.about4")}}</li>
                    </ul>
                </div>
                <div id="details-button" class="btn-container" style="margin-top: 24px;">
                    <router-link class="item" :to="{ name: 'Events'}">
                        <img src="../assets/images/btn-yellow.svg" class="yellow-btn"/>
                        <div class="centered" id="details-button-text" :class="{ adjustedPos: this.$i18n.locale == 'en' }">
                            {{$t("application.details")}}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 24px;">
            <div class="d-none d-lg-block apply-button">
                <a :href="$GOOGLE_FORM_URL" class="desktop" target="_blank"></a>
                <img class="img-fluid details-image" :src="require(`../assets/images/${$t('application.detailImgLink')}`)"/>
            </div>

            <div style="width: 100%" class="d-block d-lg-none apply-button">
                <a :href="$GOOGLE_FORM_URL" class="mobile" target="_blank"></a>
                <img style="width: 100%" class="img-fluid details-image" :src="require(`../assets/images/${$t('application.detailMobileImgLink')}`)"/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'methods',
    title: '小小博物館 KOL'
  }
</script>
<style scoped>
    #content {
        margin-top: 30px;
    }
    .subtitle-2 {
        font-size: 24px;
        font-weight: bold;
    }
    div.btn-blue {
        background-repeat: no-repeat;
        margin-bottom: 100px;
        width: 100%;
        height: 85px;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000000;
        font-weight: bold;
    }
    @media(max-width: 996px) {
        img#method-1 {
            width: 200px;
            margin-top:24px;
        }
        div.col-custom {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
    @media(min-width: 996px) {
        div#special-text {
            position: relative;
            /**left: -65px;**/
            margin-top: 16px;
        }
    }
    @media(min-width: 996px) {
        #details-button {
            text-align: left;
        }
        #details-button-text {
            left: 65%;
        }
        #details-button-text.adjustedPos {
            left: 63%;
        }
    }
    .details-image {
        min-height: auto;
        align-self: flex-start;
    }
    .apply-button {
        position: relative;
    }
    .apply-button a {
        position: absolute;
    }
    .apply-button a.desktop {
        top: 22.5%;
        left: 18%;
        width: 22%;
        height: 3%;
    }
    .apply-button a.mobile {
        top: 12%;
        left: 28%;
        width: 45%;
        height: 5%;
    }
</style>