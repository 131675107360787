<template>
  <div>
    <div id="desktop-header" class="container d-none d-lg-block">
      <div class="title">
        {{$t("museumSelect.highlights")}}
      </div>
    </div>
    <div id="mobile-header" class="d-block d-lg-none">
      <div class="title">
        {{$t("museumSelect.highlights")}}
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div class="container">
        <div class="subtitle">
          {{$t("museumSelect.subtitle")}}
        </div>
      </div>
      <div class="row" id="content" style="margin-bottom: 38px;">
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{$t("museumSelect.art")}}
          </div>
          <a target="_blank" :href="$t('museumSelect.artLink')"><img src="../assets/images/resources-1.png" class="img-fluid resource-img" /></a>
        </div>
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{$t("museumSelect.historyAndCulture")}}
          </div>
          <a target="_blank" :href="$t('museumSelect.historyLink')"><img src="../assets/images/resources-2.png" class="img-fluid resource-img" /></a>
        </div>
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{$t("museumSelect.science")}}
          </div>
          <a target="_blank" :href="$t('museumSelect.scienceLink')"><img src="../assets/images/resources-3.png" class="img-fluid resource-img" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'news',
    title: '小小博物館 KOL'
  }
</script>
<style scoped>
  #content .topic {
    text-align: center;
    font-size: 25px;
  }
  #content .item {
    margin-top: 40px;
  }
  img.resource-img {
    max-width: 260px;
  }
  div.center {
    text-align: center;
  }
</style>