<template>
    <div class="zoomWrapper">
        <div class="overlay" @click="cb()"></div>
        <div class="mapWrapper">
            <div class="mapImgWrapper">
                <div class="zoomButtonWrapper">
                    <img class="zoomIn" src="../assets/images/zoomIn.png" @click="zoomIn()"/>
                    <img class="zoomOut" src="../assets/images/zoomOut.png" @click="zoomOut()"/>
                </div>
                <div 
                    class="zoomImg" 
                    ref="zoomImg" 
                    :style="`background-image:url(${imgUrl});background-size:${100*scale}%`">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "MapZoomer",
        props:['imgUrl','cb'],
        data(){
            return {
                scale:1,
                ready:false
            }
        },
        created(){
            
        },
        methods:{
            drag(e){
                var x = e.clientX;
                var y = e.clientY;
                // The context of this is the "map" element
                this.style.backgroundPositionX = x + 'px';
                this.style.backgroundPositionY = y + 'px';
            },
            zoomFunction(){

            },
            zoomIn(){
                if(this.scale >= 1) {
                    this.scale +=0.1
                }
            },
            zoomOut(){
                if(this.scale > 1) {
                    this.scale -=0.1
                }
            }
        }
    }
</script>
<style scoped>
    .zoomWrapper {
        z-index:999;
        position: fixed;
        top:25%;
        left:0;
        width:100vw;
        height:100vh;
    }
    .mapWrapper {
        background-image: url('../assets/images/mapBg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .zoomImg {
        background-repeat: no-repeat;
        background-position: center;
    }
    .mapImgWrapper {
        background-color: #FFF;
    }
    .zoomIn {
        cursor:pointer;
        position:absolute;
        height:25%;
        left:-10%;
        top:-30.5%;
    }
    .zoomOut {
        cursor: pointer;
        position:absolute;
        height:25%;
        left:5%;
        top:-32.5%;
    }
    @media(min-width:992px){
        .overlay {
            background-color:#000000;
            opacity: 0.8;
            filter:blur(8px);
            z-index:1;
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
        .mapWrapper {
            z-index:2;
            position:relative;
            top:0;
            bottom:0;
            left:0;
            right:0;
            width:800px;
            height:670px;
            margin:auto;
        }
        .mapImgWrapper {
            width:70%;
            height:55%;
            position: absolute;
            top:25%;
            left:10%;
        }
        .zoomImg {
            width:100%;
            height:100%;
        }
    }
    @media (max-width:992px){
        .zoomWrapper {
            display:none;
        }
    }
</style>