var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"html-dom-form-framework"}},[_c('div',{staticClass:"container",staticStyle:{"margin-bottom":"12px"}},[_c('carousel',{attrs:{"perPage":1,"paginationActiveColor":"#EA9852","autoplay":true}},_vm._l((_vm.bannerItems),function(item,index){return _c('slide',[_c('a',{attrs:{"href":item.href}},[_c('div',{staticClass:"banner",style:({
                'background-image': 'url(' + item.image + ')',
              })})])])}),1),_c('hr',{staticClass:"c-hr d-none d-sm-block d-xs-block"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 section home-item text"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.highlights")))])]),_c('div',{staticClass:"col-6 section home-item text"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.kolVid")))])]),_c('div',{staticClass:"col-6 section home-item",on:{"click":function($event){return _vm.goToPage('news')}}},[_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-1-desktop.png') + ')',
            })}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-1-mobile.png') + ')',
            })})]),_c('div',{staticClass:"col-6 section home-item",on:{"click":function($event){return _vm.goToPage('kol-video')}}},[_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
              backgroundImage:
                'url(' + require('@/assets/images/kolvid-img.png') + ')',
            })}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
              backgroundImage:
                'url(' + require('@/assets/images/kolvid-img.png') + ')',
            })})]),_c('div',{staticClass:"col-6 section home-item text"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.KolSharing")))])]),_c('div',{staticClass:"col-6 section home-item text"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("header.bloopers")))])]),_c('div',{staticClass:"col-6 section home-item",on:{"click":function($event){return _vm.goToPage('facebookPosts')}}},[_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-3-desktop.jpg') + ')',
            })}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-3-mobile.jpg') + ')',
            })})]),_c('div',{staticClass:"col-6 section home-item",on:{"click":function($event){return _vm.goToPage('bloopers')}}},[_c('div',{staticClass:"img-fluid home-img d-none d-lg-block",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-4-desktop.jpg') + ')',
            })}),_c('div',{staticClass:"img-fluid home-img d-block d-lg-none",style:({
              backgroundImage:
                'url(' + require('@/assets/images/home-4-mobile.jpg') + ')',
            })})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }