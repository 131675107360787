<template>
  <div>
    <div>
      <gallery :images="images" :index="index" @close="index = null"></gallery>
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{ $t("header.bloopers") }}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{ $t("header.bloopers") }}
        </div>
      </div>
      <div id="bloopers" class="container">
        <div class="row">
          <div class="col-12 col-lg-12 custom-title">
            {{ title }}
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-6 col-md-12">
            <div
              class="embed-responsive embed-responsive-16by9 embed-container"
            >
              <div v-html="video"></div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="section-description">
              <span v-html="description" />
            </div>
          </div>
        </div>

        <agile :options="options" :key="images.length" id="agile">
          <div
            class="slide"
            v-for="(image, i) in images"
            :key="i + 1"
            :class="`slide--${i + 1}`"
            @click="index = i"
          >
            <img :src="image" alt="" />
          </div>
          <template slot="prevButton">
            <img
              :src="require('../assets/images/arrow-left.svg')"
              class="img-fluid main-logo"
            />
          </template>
          <template slot="nextButton">
            <img
              :src="require('../assets/images/arrow-right.svg')"
              class="img-fluid main-logo"
            />
          </template>
        </agile>
        <div class="btn-container" style="margin-bottom: 91px;">
          <router-link class="item" :to="{ name: 'Bloopers' }">
            <img src="../assets/images/btn-yellow.svg" class="yellow-btn" />
            <div class="centered">
              {{ $t("blooper.all") }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueGallery from "vue-gallery";
import { VueAgile } from "vue-agile";

export default {
  name: "Bloopers",
  title: "小小博物館 KOL",
  props: ["id"],
  data() {
    return {
      title: "",
      description: "",
      video: "",
      images: [],
      index: null,
      options: {
        autoplay: false,
        dots: false,
        navButtons: true,
        slidesToShow: 3,
        mobileFirst: false,
        responsive: [
          {
            breakpoint: 996,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  methods: {
    getBloopersList: function() {
      let vue = this;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      console.log("id", vue.id);

      // FIre HTTP Request to CMS Server
      axios({
        method: "get",
        url: `${
          vue.$CMS_API_URL
        }/events_components/events-features-overview.php?lang=${locale.toUpperCase()}&ref_id=${
          vue.id
        }`,
      })
        .then((response) => {
          console.log("response.data", response.data);
          const data = response.data.data[0];
          if (data) {
            vue.title = data["title"];
            vue.video = data["preview-vid"];
            //vue.video = `<iframe src="https://player.vimeo.com/video/970268201?h=090d5c02d2?autoplay=1&loop=1&autopause=0&autoplay=1&mute=1" width="200" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
            vue.description = data["Content-Text"];
            vue.images = data["images"].map(
              (image) => `${vue.$S3_BUCKET_URL}/${image}`
            );
          }
        })
        .catch(function(error) {
          console.error("erros : ", error);
        });
    },
  },
  mounted: function() {
    this.getBloopersList();
  },
  watch: {
    "$i18n.locale": function() {
      this.getBloopersList();
    },
  },
  components: {
    gallery: VueGallery,
    agile: VueAgile,
  },
};
</script>

<style>
#bloopers {
  margin-top: 40px;
}
.section-header {
  text-align: center;
  margin-bottom: 48px;
}
.section-header .title {
  padding: 12px;
  background-color: #ea9852;
}
.custom-title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 26px;
  font-weight: bold;
}
iframe {
  width: unset;
  height: unset;
}
.section-description {
  margin-top: 12px;
}
.section-description span {
  padding-left: 15px;
  font-size: 22px;
  display: inline-block;
}
.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  /* height: 450px; */
  justify-content: center;
  padding: 0 5px;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  cursor: pointer;
}
#agile {
  margin-top: 24px;
}
.agile__slides {
  align-items: flex-start;
}
.btn-container {
  margin-top: 109px;
}
@media (max-width: 996px) {
  .btn-container {
    margin-top: 39px;
  }
}
.agile__nav-button {
  border: 0px;
  background: none;
  margin-top: 8px;
  color: white;
}
</style>
<style>
@media (min-width: 576px) {
  .embed-container {
    /* position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto; */
    aspect-ratio: 1.267;
  }
}
@media (max-width: 575px) {
  .embed-container {
    /* position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto; */
    aspect-ratio: 1;
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
}
</style>
