<template>
  <div>
    <div id="html-dom-form-framework">
      <div class="container" style="margin-bottom: 12px">
        <carousel :perPage="1" paginationActiveColor="#EA9852" :autoplay="true">
          <slide v-for="(item, index) in bannerItems">
            <a :href="item.href">
              <div
                class="banner"
                :style="{
                  'background-image': 'url(' + item.image + ')',
                }"
              ></div>
            </a>
          </slide>
        </carousel>
        <hr class="c-hr d-none d-sm-block d-xs-block" />
        <div class="row">
          <div class="col-6 section home-item text">
            <div class="section-title">{{ $t("header.highlights") }}</div>
          </div>
          <!--
                <div class="col-6 section home-item text">
                    <div class="section-title">{{$t("header.onlineExhibition")}}</div>
                </div>
                -->
          <div class="col-6 section home-item text">
            <div class="section-title">{{ $t("header.kolVid") }}</div>
          </div>
          <div class="col-6 section home-item" @click="goToPage('news')">
            <div
              class="img-fluid home-img d-none d-lg-block"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-1-desktop.png') + ')',
              }"
            />
            <div
              class="img-fluid home-img d-block d-lg-none"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-1-mobile.png') + ')',
              }"
            />
          </div>
          <div class="col-6 section home-item" @click="goToPage('kol-video')">
            <div
              class="img-fluid home-img d-none d-lg-block"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/kolvid-img.png') + ')',
              }"
            />
            <div
              class="img-fluid home-img d-block d-lg-none"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/kolvid-img.png') + ')',
              }"
            />
          </div>
          <!--
                <div class="col-6 section home-item" @click="goToPage('online-exhibition')">
                    <div 
                        class="img-fluid home-img d-none d-lg-block" 
                        :style="{ backgroundImage: 'url(' + require('@/assets/images/home-2-desktop.jpg') + ')' }"
                    />
                    <div 
                        class="img-fluid home-img d-block d-lg-none" 
                        :style="{ backgroundImage: 'url(' + require('@/assets/images/home-2-mobile.jpg') + ')' }"
                    />
                </div>
            -->
          <div class="col-6 section home-item text">
            <div class="section-title">{{ $t("header.KolSharing") }}</div>
          </div>
          <div class="col-6 section home-item text">
            <div class="section-title">{{ $t("header.bloopers") }}</div>
          </div>
          <div
            class="col-6 section home-item"
            @click="goToPage('facebookPosts')"
          >
            <div
              class="img-fluid home-img d-none d-lg-block"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-3-desktop.jpg') + ')',
              }"
            />
            <div
              class="img-fluid home-img d-block d-lg-none"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-3-mobile.jpg') + ')',
              }"
            />
          </div>
          <div class="col-6 section home-item" @click="goToPage('bloopers')">
            <div
              class="img-fluid home-img d-none d-lg-block"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-4-desktop.jpg') + ')',
              }"
            />
            <div
              class="img-fluid home-img d-block d-lg-none"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/home-4-mobile.jpg') + ')',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import axios from "axios";

export default {
  name: "home",
  title: "小小博物館 KOL",
  data: function() {
    return {
      bannerItems: [],
    };
  },
  methods: {
    goToPage(page) {
      switch (page) {
        case "news":
          this.$router.push("news");
          break;
        case "online-exhibition":
          this.$router.push("online-exhibition");
          break;
        case "facebookPosts":
          this.$router.push("kol-sharing");
          break;
        case "kol-video":
          this.$router.push("kol-video");
          break;
        case "bloopers":
          this.$router.push("event-highlights");
          break;
      }
    },
    getBannerItems: function() {
      var vue = this;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/banner-components/banner-render-data-v2.php?lang=${locale}`,
      })
        .then((response) => {
          console.log(response.data);
          vue.bannerItems = response.data.map((item) => {
            return {
              image: vue.$S3_BUCKET_URL + "/" + item.imgPath,
              href:
                item.href != null
                  ? item.href.substring(0, 7) === "http://" ||
                    item.href.substring(0, 8) === "https://"
                    ? item.href
                    : `${location.protocol}//${item.href}`
                  : "#",
            };
          });
        })
        .catch(function(error) {
          console.error("erros : ", error);
        });
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mounted: function() {
    this.getBannerItems();
  },
  watch: {
    "$i18n.locale": function() {
      this.getBannerItems();
    },
  },
};
</script>
<style scoped>
.banner {
  background-color: rgb(255, 253, 238);
  width: 100%;
  max-height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  max-height: 1000px;
  height: 206px;
}
@media (min-width: 992px) {
  #html-dom-form-framework {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .banner {
    height: 360px;
  }
}
@media (min-width: 1140px) {
  .banner {
    height: 420px;
  }
}
.home-img {
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
}
@media (min-width: 992px) {
  .home-img {
    height: 300px;
    padding-bottom: 0px;
  }
}
.section .section-title {
  color: #ea9852;
  font-size: 32px;
}
@media (max-width: 576px) {
  .section .section-title {
    font-size: 20px;
  }
}
.section {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
}
div.home-item {
  cursor: pointer;
}
@media (max-width: 992px) {
  div.home-item.text {
    margin-top: 24px;
  }
}
</style>
<style>
.VueCarousel-dot-container {
  margin-top: 12px !important;
}
.VueCarousel-dot {
  margin-top: 0px !important;
}
</style>
