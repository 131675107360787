<template>
  <div id="app">
    <Header />
    <router-view id="main"></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form_data: ["a", "b"],
    };
  },
};
</script>

<style>
@import "./assets/styles/bootstrap-4.css";
@font-face {
  font-family: "Noto Sans CJK TC";
  src: local("Merienda"),
    url(./assets/fonts/NotoSansCJKtc-Regular.otf) format("truetype");
}
#app {
  font-family: "Noto Sans CJK TC", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: #ffffff;
  min-height: 100%;
}
#main {
  min-height: calc(100vh - 242px - 42px);
}
body {
  background-image: url("./assets/images/Blue_BG_dark.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  overflow: auto;
  font-size: 0.8rem;
}
html {
  overflow: hidden;
  height: 100%;
}
hr.c-hr {
  border: 4px solid #ea9852;
}
.title {
  font-size: 30px;
  background-color: #9fa8bf;
  text-align: center;
  padding: 3px;
  font-weight: bold;
}
@media (max-width: 992px) {
  .title {
    font-size: 20px;
  }
}
.subtitle {
  font-size: 30px;
  text-align: center;
  margin-top: 26px;
  font-weight: bold;
}
@media (max-width: 992px) {
  .subtitle {
    font-size: 23px;
  }
}
.normal-text {
  color: #ffffff;
  font-size: 17px;
}
.btn-container {
  position: relative;
  text-align: center;
  color: #2f94b9;
  font-size: 28px;
  font-weight: bold;
}
.btn-container a {
  color: #2f94b9;
}
@media (max-width: 996px) {
  .btn-container {
    font-size: 25px;
  }
}
.centered {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.yellow-btn {
  width: 280px;
}
</style>
