<template>
  <div>
    <div>
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{$t("header.bloopers")}}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{$t("header.bloopers")}}
        </div>
      </div>
      <div class="year-tab">
        <span @click="switchTab('2021')" :class="{ selected: this.year === '2021' }">2021</span>
        <span>|</span>
        <span @click="switchTab('2022')" :class="{ selected: this.year === '2022' }">2022</span>
        <span>|</span>
        <span @click="switchTab('2023')" :class="{ selected: this.year === '2023' }">2023</span>
        <span>|</span>
        <span @click="switchTab('2024')" :class="{ selected: this.year === '2024' }">2024</span>
      </div>
      <div id="bloopers" class="container">
        <div class="row justify-content-md-center">
          <template v-for="(blooper, index) in blooperList">
            <div :key="blooper.id" class="col-12 col-lg-5">
              <router-link class="item no-decoration" :to="{ name: 'BlooperDetails', query: { id: blooper.ref_id } }">
                <div class="section-header">
                  <div class="title">
                      {{blooper.title}}
                  </div>
                </div>
                <div style="text-align: center">
                  <img class="img-fluid section-image" :src="blooper['cover-img']"/>
                </div>
                <div class="normal-text section-description">
                  {{blooper['preview-text']}}
                </div>
              </router-link>
            </div>
            <div :key="blooper.id" class="col-lg-1" v-if="index % 2 == 0"/>
            <div class="col-12 d-none d-lg-block" :key="blooper.id" v-if="index % 2 == 1">
              <hr class="c-hr"/>
            </div>
            <div class="col-12 d-block d-lg-none" :key="blooper.id">
              <hr class="c-hr"/>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "Bloopers",
    title: "小小博物館 KOL",
    data() {
      return {
          blooperList: [],
          year: "2024"
      }
    },
    methods: {
      switchTab: function(year) {
        this.year = year
        this.getBloopersList()
      },
      getBloopersList: function() {
        let vue = this
        vue.blooperList = []

        // Get current locale
        let locale = "tc";
        if (["sc","tc","en"].includes(vue.$i18n.locale))
            locale = vue.$i18n.locale;

        // FIre HTTP Request to CMS Server
        axios({
          method: "get",
          url : `${vue.$CMS_API_URL}/events_components/events-features-overview.php?lang=${locale.toUpperCase()}&year=${this.year}`
        }).then((response) => {
          console.log("response", response.data);
          if (response.data && response.data.data) {
            vue.blooperList = response.data.data.map(blooper => {
              return Object.assign(blooper, {
                'cover-img': `${vue.$S3_BUCKET_URL}/${blooper['cover-img']}`
              });
            });
            console.log("vue.blooperList", vue.blooperList);
          }
        }).catch(function(error){
            console.log('erros : ',error);
        });
      }
    },
    mounted: function() {
      this.getBloopersList();
    },
    watch: {
      '$i18n.locale': function() {
        this.getBloopersList();
      }
    }
  };
</script>

<style scoped>
  #bloopers {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .section-header {
    text-align: center;
    margin-bottom: 48px;
  }
  .section-header .title {
    padding: 12px;
    background-color: #EA9852;
  }
  .section-image {
    border-radius: 50%;
  }
  .section-description {
    margin-top: 24px;
  }
  hr.c-hr {
    border: 4px solid;
    border-color: #ffffff; 
    margin: 36px 0px;
  }
  .year-tab {
    text-align: center;
    margin-top: 40px;
    max-width: 100%;
    overflow-x: auto;
  }
  .year-tab span {
    font-size: 2rem;
    margin: 0 12px;
    cursor: pointer;
  }
  .year-tab span.selected {
    color: #EA9852;
    font-weight: bold;
  }

</style>

<style>
  .no-decoration, .no-decoration:hover {
    text-decoration: none;
    color: unset;
  }
</style>