<template>
    <div class="wrap" id="footer">
        <div class="container">
            <div id="footer-upper">
                <span class="item first" @click="goToContactUs()" >{{$t("footer.contactUs")}}</span>
                <span class="item" @click="goToOrdinance()" >{{$t("footer.terms")}}</span>
                <a :href="$t('footer.ordinanceURL')">{{$t("footer.privacy")}}</a>
                <div style="display: inline-block">
                    <img onclick="window.location.href='https://www.facebook.com/VisitHKMuseums/ '" class="img-fluid social-media-img first" src="../assets/images/facebook-icon.svg"/>
                    <img onclick="window.location.href='https://www.instagram.com/hkmuseums/'" class="img-fluid social-media-img" src="../assets/images/instagram-icon.svg"/>
                    <img onclick="window.location.href='https://www.youtube.com/user/HKPublicMuseums'" class="img-fluid social-media-img" src="../assets/images/youtube-icon.svg"/>
                </div>
            </div>
            <div id="footer-lower">
                <span>{{$t("footer.allRightReserved")}}</span>
            </div>
        </div>
    </div>
</template>
 
<script>
    export default {
        name: 'footer',
        methods: {
            goToOrdinance() {
                window.location.href = `/${this.$i18n.locale}/terms-and-condition`;
            },
            goToContactUs() {
                window.location.href = `/${this.$i18n.locale}/contact-us`;
            }
        }
    }
</script>
<style scoped>
    #footer {
        width: 100%;
        height: 82px;
        background-color: #E94E2F;
    }
    #footer .container {
        text-align: center;
        background-color: #E94E2F;
    }
    #footer #footer-upper {
        font-size: 16px;
        padding-top: 14px;
        padding-bottom: 9px;
    }
    @media(max-width: 992px) {
        #footer #footer-upper {
            font-size: 13px;
            text-align: left;
        }
    }
    #footer #footer-lower {
        font-size: 11px;
        padding-bottom: 18px;
    } 
    #footer #footer-upper a, #footer #footer-upper span {
        color: #ffffff;
        text-decoration: none;
        padding: 0px 10px;
        display: inline; 
        border-left: 2px solid; 
        border-color: #FFFFFF;
        cursor: pointer;
    }
    #footer #footer-upper a.first, #footer #footer-upper span.first  {
        border-left: none; 
        padding-left: 0px;
    } 
    img.social-media-img {
        margin-right: 12px;
        cursor: pointer;
    }
    @media(max-width: 992px) {
        img.social-media-img {
            margin-right: 8px;
        }
        #footer-lower {
            text-align: left;
        }
    }
    @media(min-width: 992px) {
        img.social-media-img.first {
            margin-left: 24px
        }
    }
</style>