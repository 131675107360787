<template>
  <div>
    <div>
      <gallery :images="images" :index="index" @close="index = null"></gallery>
      <div id="desktop-header" class="container d-none d-lg-block">
        <div class="title">
          {{ $t(`kolVid.themeTitle${type}`) }}
        </div>
      </div>
      <div id="mobile-header" class="d-block d-lg-none">
        <div class="title">
          {{ $t(`kolVid.themeTitle${type}`) }}
        </div>
      </div>
      <div id="bloopers" class="container">
        <div class="nav-section">
          <span
            v-if="$i18n.locale == 'en'"
            class="nav-button"
            @click="prev()"
            :style="{ visibility: hasPrev ? 'visible' : 'hidden' }"
            ><img src="../assets/images/Previous video_ENG.png"
          /></span>
          <span
            v-if="$i18n.locale == 'tc'"
            class="nav-button"
            @click="prev()"
            :style="{ visibility: hasPrev ? 'visible' : 'hidden' }"
            ><img src="../assets/images/Previous video_TC.png"
          /></span>
          <span
            v-if="$i18n.locale == 'sc'"
            class="nav-button"
            @click="prev()"
            :style="{ visibility: hasPrev ? 'visible' : 'hidden' }"
            ><img src="../assets/images/Previous video_SC.png"
          /></span>
          <span
            v-if="$i18n.locale == 'en'"
            class="nav-button"
            @click="next()"
            v-show="hasNext == true"
            ><img src="../assets/images/Next video_ENG.png"
          /></span>
          <span
            v-if="$i18n.locale == 'tc'"
            class="nav-button"
            @click="next()"
            v-show="hasNext == true"
            ><img src="../assets/images/Next video_TC.png"
          /></span>
          <span
            v-if="$i18n.locale == 'sc'"
            class="nav-button"
            @click="next()"
            v-show="hasNext == true"
            ><img src="../assets/images/Next video_SC.png"
          /></span>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 custom-title">
            {{ title }}
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-6">
            <div class="embed-responsive embed-responsive-16by9">
              <div v-html="video"></div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="section-description normal-text">
              <span v-html="description" />
            </div>
          </div>
        </div>
        <!--
        <agile 
            :options="options"
            :key="images.length"
            id="agile"
        >
          <div class="slide" v-for="(image, i) in images" :key="i+1" :class="`slide--${i+1}`" @click="index = i">
              <img :src="image" alt="">
          </div>
          <template slot="prevButton">
            <img :src="require('../assets/images/arrow-left.svg')" class="img-fluid main-logo"/>
          </template>
          <template slot="nextButton">
            <img :src="require('../assets/images/arrow-right.svg')" class="img-fluid main-logo"/>
          </template>
        </agile>

        <div class="btn-container" style="margin-bottom: 91px;">
          <router-link class="item" :to="{ name: 'Bloopers' }">
            <img src="../assets/images/btn-yellow.svg" class="yellow-btn" />
            <div class="centered">
              {{ $t("blooper.all") }}
            </div>
          </router-link>
        </div>
                -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueGallery from "vue-gallery";
import { VueAgile } from "vue-agile";

export default {
  name: "Bloopers",
  title: "小小博物館 KOL",
  props: ["id"],
  data() {
    return {
      title: "",
      description: "",
      type: this.$route.query.type,
      idList: [],
      year: "2023",
      video: "",
      images: [],
      hasPrev: true,
      hasNext: true,
      index: null,
      options: {
        autoplay: false,
        dots: false,
        navButtons: true,
        slidesToShow: 3,
        mobileFirst: false,
        responsive: [
          {
            breakpoint: 996,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  methods: {
    checkItemIndex() {
      const position = this.idList.indexOf(this.id);
      if (position == 0) {
        this.hasPrev = false;
      } else {
        this.hasPrev = true;
      }
      if (position == this.idList.length - 1) {
        this.hasNext = false;
      } else {
        this.hasNext = true;
      }
    },
    prev() {
      if (this.hasPrev) {
        const position = this.idList.indexOf(this.id);
        const target = this.idList[position - 1];
        this.$router.push({
          name: `KolVidDetails`,
          query: { type: this.type, id: target },
        });
      }
    },
    next() {
      if (this.hasNext) {
        const position = this.idList.indexOf(this.id);
        const target = this.idList[position + 1];
        this.$router.push({
          name: `KolVidDetails`,
          query: { type: this.type, id: target },
        });
      }
    },
    getBloopersIdList: function() {
      let vue = this;
      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      // FIre HTTP Request to CMS Server
      axios({
        method: "get",
        url: `${
          vue.$CMS_API_URL
        }/events_components/fun-facts-overview.php?lang=${locale.toUpperCase()}&year=${
          this.year
        }&type=${this.type}`,
      }).then((response) => {
        if (response.data && response.data.data) {
          vue.idList = response.data.data
            .map((blooper) => {
              return Object.assign(blooper, {
                "cover-img": `${vue.$S3_BUCKET_URL}/${blooper["cover-img"]}`,
              });
            })
            .map((e) => e.ref_id);
        }
        this.checkItemIndex();
      });
    },
    getBloopersList: function() {
      let vue = this;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;
      // FIre HTTP Request to CMS Server
      axios({
        method: "get",
        url: `${
          vue.$CMS_API_URL
        }/events_components/fun-facts-overview.php?lang=${locale.toUpperCase()}&ref_id=${
          vue.id
        }&type=${this.type}`,
      })
        .then((response) => {
          const data = response.data.data[0];
          if (data) {
            vue.title = data["title"];
            vue.video = data["preview-vid"];
            vue.description = data["Content-Text"];
            vue.images = data["images"].map(
              (image) => `${vue.$S3_BUCKET_URL}/${image}`
            );
          }
        })
        .catch(function(error) {
          console.error("erros : ", error);
        });
    },
  },
  mounted: function() {
    this.getBloopersIdList();
    this.getBloopersList();
  },
  watch: {
    "$i18n.locale": function() {
      this.getBloopersList();
    },
    id: function() {
      this.checkItemIndex();
      this.getBloopersList();
    },
  },
  components: {
    gallery: VueGallery,
    agile: VueAgile,
  },
};
</script>

<style>
#bloopers {
  margin-top: 40px;
  padding-bottom: 80px;
}
.section-header {
  text-align: center;
  margin-bottom: 48px;
}
.section-header .title {
  padding: 12px;
  background-color: #ea9852;
}
.custom-title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 26px;
  font-weight: bold;
}
iframe {
  width: unset;
  height: unset;
}
.section-description {
  margin-top: 12px;
}
.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  /* height: 450px; */
  justify-content: center;
  padding: 0 5px;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  cursor: pointer;
}
#agile {
  margin-top: 24px;
}
.agile__slides {
  align-items: flex-start;
}
.btn-container {
  margin-top: 109px;
}
@media (max-width: 996px) {
  .btn-container {
    margin-top: 39px;
  }
}
.agile__nav-button {
  border: 0px;
  background: none;
  margin-top: 8px;
  color: white;
}
.nav-section {
  margin-top: -16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.nav-section .nav-button {
  font-size: 22.4px;
  cursor: pointer;
  user-select: none;
}
.nav-section .nav-button img {
  width: 200px;
}
</style>
<style scoped>
@media (max-device-width: 550px) {
  .nav-button {
    max-width: 45%;
  }
  .nav-button img {
    width: 100% !important ;
  }
}
</style>
