<template>
    <div v-if="ready">
        <div class="container" style="position: relative">        
            <div>
            <div id="desktop-header" class="container d-none d-lg-block">
                <div class="title">
                    {{$t("header.onlineExhibitionSubTitle")}}
                </div>
            </div>
            <div id="mobile-header" class="d-block d-lg-none">
                <div class="title">
                    {{$t("header.onlineExhibitionSubTitle")}}
                </div>
            </div>
            </div>
            <div class="subtitle header">
                <div>
                    {{$t("onlineExhibition.title1")}}{{$t("onlineExhibition.title2")}}
                </div>
                <!--
                <div>
                    {{$t("onlineExhibition.title2")}}
                </div>
                -->
            </div>
            <div class="map desktop">
                <div class="map-container">
                    <img class="map" src="../assets/images/map.png"/>
                    <div class="map-button">
                        <div v-for="n in locationData.length" :key="n">
                            <img @click="openDialog(n)" :class="`map museum-${n} image`" :src="locationData[n-1].preview"/>
                                <div :class="currLocale != 'en' ? `museum-${n} caption chi` : `museum-${n} caption`">{{ produceFieldMapper(locationData[n-1],'name') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map mobile">
                <div class="row justify-content-center">
                    <div class="col-6 mobile-offset" v-for="n in locationData.length" :key="n">
                        <img @click="openDialog(n)" :class="`mobile-image`" :src="locationData[n-1].preview"/>
                        <div :class="currLocale != 'en' ? `mobile-caption chi`:`mobile-caption`">{{ produceFieldMapper(locationData[n-1],'name') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Modal -->
        <div class="blackOverlay" v-if="dialogIsOpened"></div>
        <div id="myModal" class="popup" v-if="dialogIsOpened">
            <!-- Modal content -->
           
            <div class="popup-content">
                <span class="close-button" @click="closeDialog">&times;</span>
                <div class="body-text">
                    <div :class="currLocale == 'en' ? `subtitle desktop-line-height-90` : `subtitle`">
                        {{$t("onlineExhibition.kolPrefix")}} {{produceFieldMapper(dialogData[0],'name')}} {{$t("onlineExhibition.kolSuffix")}}
                    </div>
                    <div class="row justify-content-center" style="margin-top: 2.5%">
                        <div v-for="n in dialogData" :class="n.grid == 'B' ? 'col-lg-6 box' : 'col-lg-4 box desktop-margin-top'">
                            <div class="kol-image-wrapper">
                                <img v-if="!isMobile" :class="n.grid=='B' ? `kol-image gridB`: `kol-image gridA`" :src="n.preview_img" @click="goToDetailPage(n.ref_id)" />
                                <img v-else class="kol-image" :src="n.preview_img" @click="goToDetailPage(n.ref_id)" />
                            </div>
                            <div :class="currLocale == 'sc' ? 'kol-name-sc' : 'kol-name'">
                                {{n.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        name: 'OnlineExhibition',
        title: '小小博物館 KOL',
        data() {
            return {
                dialogIsOpened: false,
                isMobile:this.getIsMobile(),
                dialogData: [],
                locationData:[],
                ready:false,
                currLocale:'en'
            }
        },
        created(){
            let vue = this;
            var lang = 'EN';
            var currLocale = this.$i18n.locale;
            switch (currLocale) {
                case 'en':
                    lang = 'EN';
                break;
                case 'tc':
                    lang = 'TC';
                break;
                case 'sc':
                    lang = 'SC';
                break;
            }
            this.currLocale = currLocale;
            axios.get(`${vue.$CMS_API_URL}/location_components/fetch-location-list.php?lang=${lang}&sort=position`).then(
                (res)=>{
                    if(res.data){
                        this.locationData = res.data.data;
                        for(var i = 0; i < this.locationData.length;i++){
                            this.locationData[i].preview = `${vue.$S3_BUCKET_URL}/${this.locationData[i].thumbnail}`
                        }
                        this.ready = true;
                        console.log(this.locationData)
                    }
                }
            )
        },
        watch: {
            '$i18n.locale': function() {
                this.getData();
            }
        },
        methods: {
            getIsMobile(){
                const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const isMobile = vw < 1200 ? true : false;
                console.log(isMobile);
                return isMobile;
            },
            openDialog: function(pos = undefined) {
                let vue = this;
                var lang = 'EN';
                var currLocale = this.$i18n.locale;
                switch (currLocale) {
                    case 'en':
                        lang = 'EN';
                    break;
                    case 'tc':
                        lang = 'TC';
                    break;
                    case 'sc':
                        lang = 'SC';
                    break;
                }
                axios.get(`${vue.$CMS_API_URL}/travel_components/fetch-travel-record-list.php?lang=${lang}&pos=${pos}&sortBy=sortingOrder`).then(
                    (res)=>{
                        if(res.data.data.length > 0){
                            this.dialogData = res.data.data;
                            for(var i = 0; i < this.dialogData.length; i++){
                                this.dialogData[i].preview_img = `${vue.$S3_BUCKET_URL}/${this.dialogData[i].preview_img}`
                                this.dialogData[i].preview_img_mobile = `${vue.$S3_BUCKET_URL}/${this.dialogData[i].preview_img_mobile}`
                            }
                            console.log(this.dialogData);
                            this.dialogIsOpened = true;
                        } else {
                            console.log("Duplicate / missing Data");
                        }
                    }
                ).catch(
                    (err)=>{
                        console.log(err)
                    }
                )
                
            },
            closeDialog: function() {
                this.dialogIsOpened = false;
            },
            goToDetailPage: function(id) {
                this.$router.push({
                    name: 'OnlineExhibitionDetails',
                    query: {
                        id
                    }
                });
            },
            produceFieldMapper(data,field){
                var lang = 'en'
                var currLocale = this.$i18n.locale;
                switch (currLocale) {
                    case 'en':
                        lang = 'en';
                    break;
                    case 'tc':
                        lang = 'chi';
                    break;
                    case 'sc':
                        lang = 'sim';
                    break;
                }
                return data[`${field}_${lang}`];
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: "wawa";
        src: url(../assets/fonts/wawa.ttf);
        font-weight: normal;
    }
    @font-face {
        font-family: "wawa-sc";
        src: url(../assets/fonts/wawa-sc.ttf);
        font-weight: normal;
    }
    @font-face {
        font-family: "cosmic-sans-ms";
        src: url(../assets/fonts/Comic-Sans-MS.ttf);
        font-weight: normal;
    }
    @font-face {
        font-family: "zcoolkuaile-regular";
        src: url(../assets/fonts/ZCOOLKuaiLe-Regular.ttf);
        font-weight: normal;
    }
    .subtitle {
        text-align: center;
        margin-top:5%;
        font-family: "cosmic-sans-ms", "wawa", "wawa-sc";
    }
    .subtitle.header {
        font-size: 4.5vw;
    }
    @media (min-width:1200px){
        .subtitle.header > div {
            left:0;
            right:0;
            max-width:1110px;
        }
        .desktop-line-height-90 {
            line-height: 90%;
        }
        .desktop-margin-top {
            margin-top: 7.5%!important;
        }
        .box:has(.gridA):nth-child(2){
            /*margin-top:15.5%!important;*/
        }
        .box:has(.gridA):nth-child(3){
            /*margin-top:5%!important;*/
        }
        .kol-image-wrapper {
            /*aspect-ratio: 4/3;*/
        }
        .blackOverlay {
            position:fixed;
            top:0;
            bottom:0;
            left:0;
            right:0;
            z-index: 100;
            width:100vw;
            height:100vh;
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        }
        .body-text {
            margin-top:-5%;
        }
        .caption {
            font-size: 14px!important;
        }
        .caption.chi {
            font-size: 18px!important;
        }
        .kol-image-wrapper img{
            object-fit: contain;
            height:100%;
        }
        .kol-image-wrapper:has(.gridB) {
            aspect-ratio: 1/0.6;
        }
        .kol-image-wrapper {
            margin:0.5rem 0.5rem 0 0.5rem;
            padding:0;
        }
        .kol-image-wrapper > .gridB {
            width:100%!important;
        }
        .body-text .subtitle {
            margin-top:2%;
        }
    }
    @media(min-width: 1200px) and (max-width: 1600px) {
        .subtitle.header {
            position: absolute;
            font-size: min(2.8vw,32px);
            width: 100%;
        }
    }
    @media (max-width:1200px){
        .subtitle.header {
            font-size: 6vw;
        }
        .subtitle {
            margin-bottom:15%;
        }
        .popup {
            height:100vh;
            top:0;
            left:0;
            margin:0;
        }
        .kol-name, .kol-name-sc {
            font-size: 7.5vw!important;
            margin-bottom:1.5rem;
        }
    }
    @media(min-width: 1600px) {
        .subtitle.header {
            position: absolute;
            font-size: min(1.7vw,26px);
            width: 100%!important;
        }
    }
    /* The Modal (background) */
    .popup {
        max-width: 1400px;
        position: fixed; /* Stay in place */
        aspect-ratio: 5/2.9;
        z-index: 9999; /* Sit on top */
        left: 0;
        top: 0;
        right:0;
        bottom:0;
        margin:auto;
        width: 100%; /* Full width */
        overflow: hidden; /* Enable scroll if needed */
    }

    /* Modal Content */
    .popup-content {
        transform: scale(1.05);
        transform-origin: center;
        background-image: url('../assets/images/popup-background.png');
        background-size: 100% auto;
        margin: auto;
        padding: 14%;
        width: 80%;
        height: 100%;
        background-repeat: no-repeat;
    }

    @media (max-width: 1200px) {
        .popup-content {
            position: fixed;
            height: 100vh;
            overflow: auto;
            background-size: cover;
            background-position: center;
            background-image: url('../assets/images/popup-background-mobile.png');
            margin: 0;
            width: 100%;
        }
    }
    @media (min-width:1200px){
        .popup-content {
            aspect-ratio: 5/2.68;
            margin:auto;
        }
        .popup-content .body-text {
            transform: scale(0.95);
            transform-origin: center;
        }
    }

    .popup-content .body-text {
        color: black;
    }

    /* The Close Button */
    .close-button {
        color: #000;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close-button:hover,
    .close-button:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

    .kol-image-wrapper {
        overflow: hidden;
        text-align: center;
    }

    .kol-name {
        font-size: min(1.85vw,26px);
        text-align: center;
        font-family: "cosmic-sans-ms", "wawa", "wawa-sc";
    }

    .kol-name-sc {
        font-size: min(1.85vw,26px);
        text-align: center;
        font-family: "zcoolkuaile-regular";
    }

    .kol-image {
        width: 100%;
        height: auto;
        cursor: pointer;
    }
    .kol-selection {
        padding: 12px 10vw;
    }
    .map {
        width: 100%;
    }
    @media (max-width:1200px) {
        .map.mobile {
            margin-bottom: 24px;
        }
    }
    @media(max-width: 1200px) {
        .map.desktop {
            display: none;
        }
        .mobile-offset {
            margin-top:1rem;
            margin-bottom:2rem;
        }
        .popup.desktop {
            display: none;
        }
        .kol-selection {
            height:auto;
            overflow: auto;
        }
    }
    @media(min-width: 1200px) {
        .close-button {
            margin-top:-10%;
            display: block;
        }
        .map.mobile {
            display: none;
        }
        .popup.mobile {
            display: none;
        }
        .kol-image {
            object-fit: contain;
            height:100%;
        }
    }
    .mobile-image {
        width: 100%;
        padding: 0 12%;
    }
    .mobile-caption {
        text-align: center;
        font-family: "cosmic-sans-ms", "wawa","wawa-sc";
        font-size:5vw;
    }
    .mobile-caption.chi {
        font-size: 5vw;
    }
    .map-container {
        position: relative;
    }

    .caption {
        border-radius: 25px;
        opacity: 0.8;
        color: black;
        font-size: 150%;
        padding: 12px 8px;
        text-align: center;
        display: none;
        background-color: white;
        z-index: 1;
        font-family: "cosmic-sans-ms","wawa","wawa-sc";
    }
    .image {
        visibility: visible;
        cursor: pointer;
        z-index: 2;
    }
    .image:hover {
        transform: scale(1.2);
    }
    .image:hover + .caption {
        display: block;
    }

    .museum-1 {
        position: absolute;
        bottom: -100px;
        right: 26px;
    }
    .museum-1.image {
        width: 350px;
        z-index: 11;
    }
    .museum-1.caption {
        width: 320px;
        height: 150px;
        z-index: 10;
        bottom: 60px;
    }
    .museum-6 {
        position: absolute;
        bottom: 24px;
        left: 89px;
    }
    .museum-6.image {
        width: 220px;
        z-index: 13;
    }
    .museum-6.caption {
        width: 220px;
        height: 150px;
        bottom: 120px;
        z-index: 12;
    }
    .museum-15 {
        position: absolute;
        bottom: 122px;
        left: 300px;
    }
    .museum-15.image {
        width: 160px;
    }
    .museum-15.caption {
        width: 160px;
        height: 150px;
        bottom: 185px;
    }
    .museum-2 {
        position: absolute;
        bottom: 90px;
        left: 504px;
    }
    .museum-2.image {
        width: 200px;
    }
    .museum-2.caption {
        width: 200px;
        height: 150px;
        bottom: 160px;
    }
    .museum-5 {
        position: absolute;
        bottom: 135px;
        left: 720px;
    }
    .museum-5.image {
        width: 200px;
        z-index: 9;
    }
    .museum-5.caption {
        width: 200px;
        height: 160px;
        bottom: 180px;
        z-index: 8;
    }

    .museum-4 {
        position: absolute;
        bottom: 220px;
        right: 18px;
    }
    .museum-4.image {
        width: 190px;
        transform: rotate(8deg);
        z-index: 7;
    }
    .museum-4.image:hover {
        transform: scale(1.2) rotate(8deg);
    }
    .museum-4.caption {
        width: 190px;
        height: 150px;
        bottom: 300px;
        z-index: 6;
    }

    .museum-7 {
        position: absolute;
        top:320px;
        right: 220px;
    }
    .museum-7.image {
        width: 180px;
        z-index: 7;
    }
    .museum-7.caption {
        width: 190px;
        height: 140px;
        top: 280px;
        z-index: 6;
    }

    .museum-13 {
        position: absolute;
        top: 300px;
        left: 545px;
    }
    .museum-13.image {
        width: 150px;
        z-index: 7;
    }
    .museum-13.caption {
        width: 160px;
        height: 160px;
        top: 230px;
        z-index: 6;
    }

    .museum-8 {
        position: absolute;
        top: 300px;
        left: 360px;
    }
    .museum-8.image {
        width: 170px;
        z-index: 7;
    }
    .museum-8.caption {
        width: 170px;
        height: 150px;
        top: 270px;
        z-index: 6;
    }

    .museum-9 {
        position: absolute;
        top: 280px;
        left: 220px;
    }
    .museum-9.image {
        width: 120px;
        z-index: 7;
    }
    .museum-9.caption {
        width: 125px;
        height: 80px;
        top: 240px;
        z-index: 6;
    }

    .museum-10 {
        position: absolute;
        top: 200px;
        left: 320px;
    }
    .museum-10.image {
        width: 180px;
    }
    .museum-10.caption {
        width: 180px;
        height: 120px;
        top: 200px;
    }

    .museum-14 {
        position: absolute;
        top: 220px;
        right: 300px;
    }
    .museum-14.image {
        width: 160px;
    }
    .museum-14.caption {
        width: 160px;
        height: 120px;
        top: 220px;
    }

    .museum-3 {
        position: absolute;
        top: 200px;
        right: 85px;
    }
    .museum-3.image {
        width: 180px;
    }
    .museum-3.caption {
        width: 180px;
        height: 160px;
        top: 150px;
    }

    .museum-11 {
        position: absolute;
        bottom: 260px;
        left: 65px;
    }
    .museum-11.image {
        width: 180px;
        z-index: 9;
    }
    .museum-11.caption {
        width: 180px;
        height: 120px;
        bottom: 340px;
        z-index: 8;
    }

    .museum-12 {
        position: absolute;
        bottom: 200px;
        left: 78px;
    }
    .museum-12.image {
        width: 140px;
        z-index: 11;
    }
    .museum-12.caption {
        width: 200px;
        height: 160px;
        bottom: 230px;
        z-index: 10;
    }


</style>