
<template>
    <div class="container">
        <div>
            <div class="title" style="margin-top:24px;"> {{$t("uploadVideo.title1")}} </div>
            <form method="POST" role="form" @submit="submitVideo" v-if="!submitSuccess" ref="formHTML" class="form-horizontal" id="uploadForm" enctype="multipart/form-data">
                <!-- <div class="form-group">
                    <label for="title" class="control-label subtitle">短片名稱</label>
                    <input type="text" class="form-control" id="title" name="title" v-model="title" @keypress="isNotExceedLength($event, 120)">
                </div>
                <div class="form-group">
                    <label for="introduction" class="control-label subtitle">短片簡介(不多於150字) （中文或英文均可）</label>
                    <textarea class="form-control" id="introduction" name="body" v-model="body"/>
                </div> -->
                <div class="form-group">
                    <label for="videoUpload" class="control-label subtitle">{{$t("uploadVideo.subtitle1")}}</label>
                    <input type="file" class="form-control-file normal-text" name="video" id="videoUpload" @change="changeFile" accept="video/mp4,video/mov,video/wmv, video/avi, video/flv"/>
                </div>
                <div class="form-group">
                    <label for="videoUrl" class="control-label subtitle">{{$t("uploadVideo.subtitle2")}}</label>
                    <input type="text" class="form-control" name="videoUrl" id="videoUrl" v-model="videoUrl"/>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <button type="submit" class="btn" id="submitBtn">
                                <div :class="{disabled: loading}" id="confirm-btn">
                                    <div style="color:#fff; font-weight:normal; padding: 2px 8px;" v-if="loading === false">{{$t("uploadVideo.button1")}}</div>
                                    <div style="color:#fff; font-weight:normal; padding: 2px 8px;" v-else>{{$t("uploadVideo.button1")}}</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="normal-text" v-if="loading">
                    {{$t("uploadVideo.warning1")}}
                </div>
                <div class="errors" v-for="(error, pos) in errors" :key="`error-${pos}`">
                    <div class="annotation">
                        {{error}}
                    </div>
                </div>
            </form>
        </div>

        <div id="success" class="normal-text" v-if="submitSuccess">
            <div class="text">
                {{$t("uploadVideo.success1")}}
            </div>
            <div class="text">
                {{$t("uploadVideo.success2")}}
            </div>
            <div class="text">
                {{$t("uploadVideo.success3")}}
            </div>
            <div class="text">
                {{$t("uploadVideo.success4")}}
            </div>
        </div> 
    </div>
</template>


<script>
  import axios from 'axios';

  export default {
    name: 'UploadVideo',
    title: '小小博物館 KOL Little Museum KOL',
    data: function () {
      return {
        email: this.$route.params.email,
        submitSuccess: false,
        errors: [],
        title: '',
        body: '',
        fileUploaded: false,
        loading: false,
        videoUrl: ''
      }
    },
    methods: {
        submitVideo(e) {
            e.preventDefault();

            if(this.email == null) {
                this.$router.push({
                    path: 'upload'
                });
            }

            if (!this.checkForm() || this.loading) {
                return;
            }

            this.loading = true;

            /* formData */
            const formData = new FormData( this.$refs.formHTML );
            formData.append('email', this.email);
            formData.append('name', "N/A");
            formData.append('title', "N/A");
            formData.append('body', "N/A");

            if (this.fileUploaded == false)
                formData.delete('video');
            else
                formData.delete('videoUrl');

            /* AJAX request */
            axios({
                method: "post",
                url: `${this.$CMS_API_URL}/vimeo_components/upload_video.php`,
                data: formData,
                headers: { 
                    "Content-Type": "multipart/form-data",
                } 
            })

            /* handle success */
            .then( response => { 
                console.log(response.data); 
                this.email = response.data.email;
                this.loading = false;
                this.submitSuccess = true;
            } )

            /* handle error */
            .catch( response => { 
                console.log(response);
                if (this.$i18n.locale == 'tc') {
                    this.errors.push('伺服器繁忙，請稍後重試');
                } else if (this.$i18n.locale == 'sc') {
                    this.errors.push('伺服器繁忙，请稍后重试');
                } else {
                    this.errors.push('Server is busy, please try again later or upload a Youtube or Vimeo link directly');
                }
                this.loading = false;
            } );
        },
        checkForm() {
            this.errors = [];

            if (
                // this.title.trim() !== "" &&
                // this.body.trim() !== "" &&
                this.fileUploaded &&
                this.videoUrl.trim() === "" ||
                !this.fileUploaded && this.videoUrl.trim() !== ""
            ) {
                return true;
            }

            // if (this.title.trim() === "") {
            //     if (this.$i18n.locale == 'tc') {
            //         this.errors.push('請輸入短片名稱');
            //     } else if (this.$i18n.locale == 'sc') {
            //         this.errors.push('请输入短片名称');
            //     } else {
            //         this.errors.push('Title of Video is required.');
            //     }
            // } 
            // if (this.body.trim() === "") {
            //     if (this.$i18n.locale == 'tc') {
            //         this.errors.push('請輸入短片簡介');
            //     } else if (this.$i18n.locale == 'sc') {
            //         this.errors.push('请输入短片简介');
            //     } else {
            //         this.errors.push('A brief description is required.');
            //     }
            // }
            if (!this.fileUploaded && this.videoUrl.trim() === "") {
                if (this.$i18n.locale == 'tc') {
                    this.errors.push('請上載影片或輸入影片連結');
                } else if (this.$i18n.locale == 'sc') {
                    this.errors.push('请上载影片或输入影片连结');
                } else {
                    this.errors.push('Video or video URL is required.');
                }
            }
            if (this.fileUploaded && this.videoUrl.trim() !== "") {
                if (this.$i18n.locale == 'tc') {
                    this.errors.push('不可以同時上載影片及輸入影片連結');
                } else if (this.$i18n.locale == 'sc') {
                    this.errors.push('不可以同时上载影片及输入影片连结');
                } else {
                    this.errors.push('Cannot upload Video while video URL is filled in.');
                }
            }

            return false;
        },
        changeFile(e) {
            if (e.target.files.length > 0){
                this.fileUploaded = true;
            } else {
                this.fileUploaded = false;
            }
        },
        isNotExceedLength(event, max) {
            if (
                this.title.length > max - 1
            ) {
                event.preventDefault();
            } else {
                return true;
            }
        }
    },
    mounted() {
        if (this.$route.params.email == null) {
            this.$router.push({
                path: 'upload'
            });
        }
    }
  }
</script>

<style scoped>
    .control-label.subtitle {
        color: #fff;
    }
    #uploadForm {
        margin: 12px 0 0 4px;
        padding-bottom: 48px;
    }
    textarea#introduction {
        height: 200px;
    }
    #submitBtn {
        min-width: 200px;
        padding: unset;
        background-color: #EA9852;
        font-size: 30px;
    }
    #confirm-btn.disabled {
        background-color: #999;
    }

    #success {
        margin-top: 12px;
    }
    #success .text {
        margin-bottom: 12px;
    }
</style>