<template>
  <div>
    <div id="desktop-header" class="container d-none d-lg-block">
      <div class="title">
        {{ $t("kolVid.landingTitle") }}
      </div>
    </div>
    <div id="mobile-header" class="d-block d-lg-none">
      <div class="title">
        {{ $t("kolVid.landingTitle") }}
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div class="container">
        <div class="subtitle">
          {{ $t("kolVid.intro") }}
        </div>
      </div>
      <div class="row" id="content" style="margin-bottom: 38px;">
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{ $t("kolVid.themeTitle1") }}
          </div>
          <router-link
            class="item no-decoration"
            :to="{ name: 'KolVid', query: { type: `1` } }"
            ><img
              src="../assets/images/kolvid-1.png"
              class="img-fluid resource-img"
          /></router-link>
        </div>
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{ $t("kolVid.themeTitle2") }}
            <br/>
            <!--{{ $t("kolVid.upcoming") }}-->
          </div>
          <router-link
            class="item no-decoration"
            :to="{ name: 'KolVid', query: { type: `2` } }"
            ><img
              src="../assets/images/kolvid-2.png"
              class="img-fluid resource-img"
            />
          </router-link>
        </div>
        <div class="col-12 col-lg-4 center item">
          <div class="topic">
            {{ $t("kolVid.themeTitle3") }}
            <br/>
            <!-- {{ $t("kolVid.upcoming") }} -->
          </div>
          <router-link
            class="item no-decoration"
            :to="{ name: 'KolVid', query: { type: `3` } }"
          >
            <img
              src="../assets/images/kolvid-3.png"
              class="img-fluid resource-img"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  title: "小小博物館 KOL",
};
</script>
<style scoped>
#content .topic {
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  height: 64px;
}
#content .item {
  margin-top: 40px;
}
img.resource-img {
  max-width: 260px;
}
div.center {
  text-align: center;
}
</style>
