<template>
    <div>
        <div>
            <div id="desktop-header" class="container d-none d-lg-block">
                <div class="title">
                    {{$t("aboutUs.title")}}
                </div>
            </div>
            <div id="mobile-header" class="d-block d-lg-none">
                <div class="title">
                    {{$t("aboutUs.title")}}
                </div>
            </div>
        </div>
        <div id="html-dom-form-framework" class="container">
            <div class="container about-us-main">
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main1")}}
                </div>
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main2")}}
                </div>
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main3")}}
                </div>
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main4")}}
                </div>
                <!--
                <div class="container normal-text" style="margin-top: 24px">
                    {{$t("aboutUs.main5")}}
                </div>
            -->
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'AboutUs',
    title: '小小博物館 KOL',
  }
</script>

<style scoped>
    .about-us-main {
        margin-bottom:64px;
    }
</style>