import cssVars from "css-vars-ponyfill";
cssVars();

import Vue from "vue";
import VueZoomer from "vue-zoomer";
import App from "./App.vue";
import Root from "./Root.vue";
import axios from "axios";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import VueGtag from "vue-gtag";

import titleMixin from "./mixins/titleMixin";
import messages from "./translation";
import constantsUAT from "./constants.json";
import constantsProd from "./constants-prod.json";
/* Pages */
import Home from "./pages/Home.vue";
import Events from "./pages/Events.vue";
import News from "./pages/News.vue";
import termsAndCondition from "./pages/termsAndCondition.vue";
import AboutUs from "./pages/AboutUs.vue";
import EventSessionRegistration from "./pages/EventSessionRegistration.vue";
import FacebookPosts from "./pages/FacebookPosts.vue";
import Bloopers from "./pages/Bloopers.vue";
import BlooperDetails from "./pages/BlooperDetails.vue";
import Methods from "./pages/Methods.vue";
import Upload from "./pages/Upload.vue";
import UploadVideo from "./pages/UploadVideo.vue";
import ContactUs from "./pages/ContactUs.vue";
import OnlineExhibition from "./pages/OnlineExhibition.vue";
import OnlineExhibitionDetails from "./pages/OnlineExhibitionDetails.vue";
import KolVidLanding from './pages/KolVidLanding.vue'
import KolVid from "./pages/KolVid.vue";
import KolVidDetails from "./pages/KolVidDetails.vue";

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.mixin(titleMixin);
Vue.use(VueZoomer);

const isProd =
  process.env.NODE_ENV.toString().trim() == "production" ? true : false;

let constants;
isProd ? (constants = constantsProd) : (constants = constantsUAT);
/* Locale path Handling */
const i18n = new VueI18n({
  fallbackLocale: constants.DEFAULT_LANG,
  messages, // set locale messages
});
const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);
export const isMobile = vw < 992 ? true : false;

/*Routes*/
const routes = [
  {
    path: "/",
    redirect: constants.DEFAULT_LANG + "/",
  },
  {
    path: "/:lang",
    component: Root,
    beforeEnter(to, from, next) {
      const lang = to.params.lang;

      if (!["tc", "en", "sc"].includes(lang)) {
        return next(constants.DEFAULT_LANG + "/");
      }

      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
      return next();
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "events",
        name: "Events",
        component: Events,
      },
      {
        path: "about-us",
        name: "AboutUs",
        component: AboutUs,
      },
      {
        path: "event-session-registraton",
        name: "EventSessionRegistration",
        component: EventSessionRegistration,
      },
      {
        path: "terms-and-condition",
        name: "termsAndCondition",
        component: termsAndCondition,
      },
      {
        path: "news",
        name: "News",
        component: News,
      },
      {
        path: "methods",
        name: "Methods",
        component: Methods,
      },
      {
        path: "kol-sharing",
        name: "FacebookPosts",
        component: FacebookPosts,
      },
      {
        path: "event-highlights",
        name: "Bloopers",
        component: Bloopers,
      },
      {
        path: "event-highlights-details",
        name: "BlooperDetails",
        component: BlooperDetails,
        props: (route) => ({ id: route.query.id }),
      },
      {
        path: "kol-video",
        name: "KolVidLanding",
        component: KolVidLanding,
      },
      {
        path: "kol-video-list",
        name: "KolVid",
        component: KolVid,
      },
      {
        path: "kol-video-details",
        name: "KolVidDetails",
        component: KolVidDetails,
        props: (route) => ({ id: route.query.id }),
      },
      {
        path: "upload",
        name: "upload",
        component: Upload,
      },
      {
        path: "upload-video",
        name: "UploadVideo",
        component: UploadVideo,
      },
      {
        path: "contact-us",
        name: "ContactUs",
        component: ContactUs,
      },
      {
        path: "online-exhibition",
        name: "OnlineExhibition",
        component: OnlineExhibition,
      },
      {
        path: "online-exhibition-details",
        name: "OnlineExhibitionDetails",
        component: OnlineExhibitionDetails,
        props: (route) => ({ id: route.query.id }),
      },
      {
        path: "*",
        name: "Home",
        component: Home,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  base: process.env.BASE_URL,
  scrollBehavior: function(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    if (to.hash) {
      console.log("hash", to.hash);
      const anchor = to.hash;
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
        console.log("hash set:", location.href);
        return { selector: anchor };
      }
    } else {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      return { x: 0, y: 0 };
    }
  },
});

/* Google Analytics to track all the routes */
Vue.use(
  VueGtag,
  {
    config: { id: constants.GOOGLE_ANALYTICS_ID },
  },
  router
);

/* Axios URL Handling */
var protocol = location.protocol;
var host = window.location.hostname;
var src = protocol + "//" + host + "/src/backend";
axios.defaults.baseURL = src;
// axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

/* Vue Constants */
Vue.prototype.$S3_BUCKET_URL = constants.S3_BUCKET_URL;
Vue.prototype.$CMS_API_URL = constants.CMS_API_URL;
Vue.prototype.$GOOGLE_FORM_URL = constants.GOOGLE_FORM_URL;

new Vue({
  render: (h) => h(App),
  router,
  i18n,
}).$mount("#app");
