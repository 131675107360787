<template>
  <div id="html-dom-form-framework">
    <div class="container" id="video-upload">
      <div class="title" style="margin-bottom: 12px">
        {{ $t("upload.title1") }}
      </div>
      <div class="normal-text">
        <div class="subtitle">{{ $t("upload.subtitle1") }}</div>
        <ol>
          <li>{{ $t("upload.content1") }}</li>
          <li>{{ $t("upload.content2") }}</li>
          <li>{{ $t("upload.content3") }}</li>
          <li>{{ $t("upload.content4") }}</li>
        </ol>
        <div class="subtitle">{{ $t("upload.subtitle2") }}</div>
        <div>
          <div>{{ $t("upload.content5") }}</div>
          <div>{{ $t("upload.content6") }}</div>
        </div>
        <div class="subtitle">{{ $t("upload.subtitle3") }}</div>
        <ul>
          <li>
            {{ $t("upload.content7") }}
            <span v-html="$t('upload.content8')"></span>
          </li>
          <!-- <li>{{$t("upload.content9")}}</li> -->
        </ul>
      </div>
      <div class="annotation">
        <div>* {{ $t("upload.annotation1") }}</div>
        <div>** {{ $t("upload.annotation2") }}</div>
      </div>
      <div class="title">
        {{ $t("upload.title2") }}
      </div>
      <input class="refCode" v-model="refCode" />
      <div id="declaration">
        <div>
          <input type="checkbox" id="checkbox1" v-model="googleFormIsChecked" />
          <label for="checkbox1" class="checkboxLabel normal-text">
            * {{ $t("upload.checkbox1a") }}
            <a class="whiteBold" :href="$GOOGLE_FORM_URL">{{
              $t("upload.checkbox1b")
            }}</a>
            {{ $t("upload.checkbox1c") }}
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="checkbox2"
            v-model="declarationIsChecked"
          />
          <label for="checkbox2" class="checkboxLabel normal-text">
            * {{ $t("upload.checkbox2a") }}
            <a
              class="whiteBold"
              :href="`../${this.$i18n.locale}/terms-and-condition`"
              >{{ $t("upload.checkbox2b") }}</a
            >
            {{ $t("upload.checkbox2c") }}
          </label>
        </div>
      </div>
      <div class="annotation">
        <div>* {{ $t("upload.annotation3") }}</div>
      </div>
      <div class="btn-container" id="confirmBtn" @click="submitRefCode()">
        <div style="color:#fff; font-weight:normal">
          {{ $t("upload.button1") }}
        </div>
      </div>
      <div class="errors" v-for="(error, pos) in errors" :key="`error-${pos}`">
        <div class="annotation">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "upload",
  title: "小小博物館 KOL Little Museum KOL",
  data: function() {
    return {
      refCode: "",
      declarationIsChecked: false,
      googleFormIsChecked: false,
      errors: [],
    };
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    submitRefCode() {
      if (!this.checkForm()) return;

      this.$router.push({
        name: "UploadVideo",
        params: {
          email: this.refCode,
        },
      });
    },
    checkForm() {
      this.errors = [];

      if (!this.declarationIsChecked || !this.googleFormIsChecked) {
        if (this.$i18n.locale == "tc") {
          this.errors.push("「必須填寫」部分尚未完成");
        } else if (this.$i18n.locale == "sc") {
          this.errors.push("「必须填写」部分尚未完成");
        } else {
          this.errors.push("Mandatory field(s) are not completed yet");
        }
        return false;
      }

      if (this.refCode.trim() == "") {
        if (this.$i18n.locale == "tc") {
          this.errors.push("請輸入已登記之電郵");
        } else if (this.$i18n.locale == "sc") {
          this.errors.push("请输入已登记之电邮");
        } else {
          this.errors.push("Please enter the email");
        }
        return false;
      }

      if (this.validateEmail(this.refCode) === false) {
        if (this.$i18n.locale == "tc") {
          this.errors.push("請輸入正確電郵");
        } else if (this.$i18n.locale == "sc") {
          this.errors.push("请输入正确电邮");
        } else {
          this.errors.push("Please enter a valid email");
        }
        return false;
      }

      return true;
    },
  },
  watch: {},
};
</script>
<style scoped>
#content .topic {
  text-align: center;
  font-size: 25px;
}
#content .item {
  margin-top: 40px;
}
img.resource-img {
  max-width: 260px;
}
div.center {
  text-align: center;
}
.refCode {
  margin-top: 12px;
  padding: 12px 6px;
  min-width: 200px;
}
@media (min-width: 968px) {
  .refCode {
    min-width: 300px;
  }
}
.checkboxLabel {
  display: inline;
  margin-left: 8px;
}
#declaration {
  margin: 24px 0;
}
#confirmBtn {
  margin-bottom: 24px;
  max-width: 200px;
  margin-top: 24px;
  cursor: pointer;
  background-color: #ea9852;
}
#confirmBtn.disabled {
  background-color: #999;
}
#video-upload .subtitle {
  text-align: left;
}
.annotation {
  margin-bottom: 24px;
  font-size: 14px;
}
a.whiteBold {
  color: white;
  text-decoration: underline;
}
</style>

<style>
.whiteBold {
  color: white !important;
  text-decoration: underline;
}
</style>
