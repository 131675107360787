<template>
  <!--
    Disclaimer: Any multi-media assets / font assets are povided by client: Ohpama (https://www.ohpama.com/);
    For any copyright issue, the development team does not have any responsibility.
  -->
  <div id="vue-nav">
    <div id="mobile-navbar" class="navbar-inverse sticky">
      <input type="checkbox" id="mobile-menu-checkbox" :checked="menuOpened">

      <nav role="navigation" class="mobile-menu">
          <div id="mobile-navbar-profile">
            <div class="lang-nav" style="padding: 24px 24px">
              <span class="item first" @click="switchLanguage('tc')">繁</span>
              <span class="item" @click="switchLanguage('sc')">簡</span>
              <span class="item" @click="switchLanguage('en')">EN</span>
            </div>
          </div>
          <div id="mobile-navbar-main">
            <router-link class="item first" :to="{ name: 'Home'}" exact>
              <span @click="menuOpened = false">{{$t("header.home")}}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'AboutUs'}" exact>
              <span @click="menuOpened = false">{{$t("header.aboutUs")}}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'Events'}" exact>
              <span @click="menuOpened = false">{{$t("header.kolProgramme")}}</span>
            </router-link>
            <!--
            <router-link class="item" :to="{ name: 'Methods'}" exact>
              <span @click="menuOpened = false">{{$t("header.registration")}}</span>
            </router-link>
            -->
            <!-- <router-link class="item" :to="{ name: 'Events'}">
              <span @click="menuOpened = false">{{ $t("header.activity") }}</span>
            </router-link> -->
            <router-link class="item" :to="{ name: 'Bloopers'}">
              <span @click="menuOpened = false">{{ $t("header.bloopers") }}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'KolVidLanding'}">
              <span @click="menuOpened = false">{{ $t("header.kolVid") }}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'OnlineExhibition'}" >
              <span @click="menuOpened = false">{{$t("header.onlineExhibition")}}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'FacebookPosts'}">
              <span @click="menuOpened = false">{{ $t("header.KolSharing") }}</span>
            </router-link>
            <router-link class="item" :to="{ name: 'News'}" >
              <span @click="menuOpened = false">{{$t("header.highlights")}}</span>
            </router-link>
            <hr/>
            <span class="item" @click="goToContactUs()" >{{$t("header.contactUs")}}</span>
            <span class="item" @click="goToOrdinance()" >{{$t("footer.terms")}}</span>
            <a class="item last" :href="$t('footer.ordinanceURL')">
              <span @click="menuOpened = false">{{$t("footer.privacy")}}</span>
            </a>
          </div>
      </nav>

      <label for="mobile-menu-checkbox" id="mobile-menu-overlay"></label>
    </div>

    <div id="vue-nav-top-desktop" class="vue-nav-top container d-none d-lg-block">
      <div class="row">
        <div class="col-5" id="logo-container">
          <div class="row">
            <div class="col-8" style="min-height: 126px;">
              <img :src="require('../assets/images/'+$t('header.mainLogo'))" @click="goToHome()" class="img-fluid main-logo"/>
            </div>
          </div>
        </div>
        <div class="col-7" id="lang-nav-container">
          <div class="lang-nav">
            <span class="item" @click="switchLanguage('tc')">繁</span>
            <span class="item" @click="switchLanguage('sc')">簡</span>
            <span class="item" @click="switchLanguage('en')">EN</span>
          </div>
        </div>
      </div>
    </div>
    <div id="vue-nav-top-mobile" class="vue-nav-top container d-block d-lg-none">
      <div class="row">
        <div class="col-8 col-sm-6 col-md-5" id="logo-container">
          <div class="row">
            <div class="col-8 offset-1" style="padding-left: 0px">
              <img :src="require('../assets/images/'+$t('header.mainLogo'))" @click="goToHome()" class="img-fluid main-logo"/>
            </div>
          </div>
        </div>
        <div class="col-4 col-sm-6 col-md-7" id="feather-menu-container" v-if="!isDisableNav">
          <img src="../assets/images/feather-menu.svg" style="height: 20px;margin-bottom: 12px;margin-right:12px" @click="menuOpened = !menuOpened"/>
        </div>
      </div>
    </div>
    <div id="vue-nav-links-desktop" class="container d-none d-lg-block" v-if="!isDisableNav">
      <router-link class="item first" :to="{ name: 'Home'}" exact>{{$t("header.home")}}</router-link>
      <router-link class="item " :to="{ name: 'AboutUs'}" exact>{{$t("header.aboutUs")}}</router-link>
      <router-link class="item " :to="{ name: 'Events'}" exact>{{$t("header.kolProgramme")}}</router-link>
      <!--<router-link class="item " :to="{ name: 'Methods'}" exact>{{$t("header.registration")}}</router-link>-->
      <!--<router-link class="item" :to="{ name: 'Events'}">{{ $t("header.activity") }}</router-link> -->
      <router-link class="item" :to="{ name: 'Bloopers'}">{{ $t("header.bloopers") }}</router-link>
      <router-link class="item" :to="{ name: 'KolVidLanding'}">{{$t("header.kolVid")}}</router-link>
      <router-link class="item" :to="{ name: 'OnlineExhibition'}">{{$t("header.onlineExhibition")}}</router-link>
      <router-link class="item" :to="{ name: 'FacebookPosts'}">{{ $t("header.KolSharing") }}</router-link>
      <router-link class="item last" :to="{ name: 'News'}">{{$t("header.highlights")}}</router-link>
    </div>
    <div id="vue-nav-links-mobile" class="d-block d-lg-none" v-if="!isDisableNav">
      <div style="white-space: nowrap;">
        <router-link class="item first" :to="{ name: 'Home'}" exact>{{$t("header.home")}}</router-link>
        <router-link class="item " :to="{ name: 'AboutUs'}" exact>{{$t("header.aboutUs")}}</router-link>
        <router-link class="item " :to="{ name: 'Events'}" exact>{{$t("header.kolProgramme")}}</router-link>
        <!--<router-link class="item " :to="{ name: 'Methods'}" exact>{{$t("header.registration")}}</router-link>-->
        <!--<router-link class="item " :to="{ name: 'Events'}">{{ $t("header.activity") }}</router-link> -->
        <router-link class="item" :to="{ name: 'Bloopers'}">{{ $t("header.bloopers") }}</router-link>
        <router-link class="item" :to="{ name: 'KolVidLanding'}">{{$t("header.kolVid")}}</router-link>
        <router-link class="item" :to="{ name: 'OnlineExhibition'}">{{$t("header.onlineExhibition")}}</router-link>
        <router-link class="item" :to="{ name: 'FacebookPosts'}">{{ $t("header.KolSharing") }}</router-link>
        <router-link class="item last" :to="{ name: 'News'}">{{$t("header.highlights")}}</router-link>
      </div>
    </div>
    <div class="container" id="hr-container">
      <hr class="c-hr d-none d-lg-block" style="margin-bottom: 0px;" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    data() {
      return{
        menuOpened: false
      }
    },
    methods: {
      goToHome() {
        window.open(`/${this.$i18n.locale}/`, '_blank');
      },
      goToOrdinance() {
        window.location.href = `/${this.$i18n.locale}/terms-and-condition`;
      },
      goToContactUs() {
          window.location.href = `/${this.$i18n.locale}/contact-us`;
          this.menuOpened = false;
      },
      switchLanguage(lang) {
        this.menuOpened = false;
        const currentPathName = window.location.pathname;
        const path = currentPathName ? currentPathName.substring(this.$i18n.locale.length+1) : currentPathName;
        const query = window.location.search || '';
        window.location.href=`/${lang}${path}${query}`;
      },
    },
    computed: {
      isDisableNav() {
        
        return this.$route.name === 'upload'
      }
    }
  }
</script>
<style scoped>
  .vue-nav-top {
    padding-top: 42px;
    padding-bottom: 17px;
  }
  #vue-nav {
    width:100%;
  }
  #vue-nav-links-desktop {
    text-align: right;
  }
  #vue-nav-links-desktop .item {
    font-size: 140%;
    color: #FFFFFF;
    padding: 0px 14px;
    text-decoration: none;
    display: inline; 
    border-left: 2px solid; 
    border-color: #EA9852;
  }
  @media(max-width: 1200px) {
    #vue-nav-links-desktop .item {
      font-size: 110%;
    }
  }
  #vue-nav-links-desktop .item.router-link-active {
    color: #EA9852;
  }
  #vue-nav-links-desktop .item.first {
    border-left: none;
  }
  #vue-nav-links-desktop .item.last {
    padding-right: 0px;
  }

  #lang-nav-container {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
  .lang-nav .item {
    margin-left: 26px;
    cursor: pointer;
  }
  .lang-nav .item.first {
    padding-left: 0px;
  }
  #vue-nav-links-mobile {
    max-width: 100vw;
    overflow: auto;
    background-color: #E94E2F;
    color: #ffffff;
    padding: 10px 0px;
    text-align: center;
  }
  #vue-nav-links-mobile::-webkit-scrollbar {
    display:none;
  }
  #vue-nav-links-mobile .item {
    border-left: 2px solid; 
    border-color: #ffffff;
    padding: 0px 8px;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
  @media(max-width: 500px) {
    #vue-nav-links-mobile {
      overflow-x: scroll;
    }
  }
  @media(max-width: 375px) {
    #vue-nav-links-mobile .item {
      font-size: 12px;
      padding: 0 4px;
    }
  }
  #vue-nav-links-mobile .item.first {
    border-left: none;
  }
  #feather-menu-container {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
  #mobile-menu-checkbox {
    display: none;
  }
  #mobile-menu-checkbox:checked ~ #mobile-menu-overlay {
    display: block;
  }
  #mobile-menu-checkbox:checked ~ .mobile-menu {
    visibility: visible;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
  #mobile-menu-overlay {
    background-color: rgba(0,0,0,0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .mobile-menu {
    position: fixed;
    right: 0px;
    z-index: 101;
    top: 0;
    visibility: hidden;
    width: 300px;
    height: 100%;
    background: #E94E2F;
    color: white;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
  }
  .mobile-menu h1 {
    color: black;
  }
  #mobile-menu-btn {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 30px;
    font-weight: 300;
    left: 0;
    line-height: 1.6em;
    position: relative;
    padding: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 14px 24px;
  }
  #mobile-navbar-main .item{
    display: block;
    color: #ffffff;
    text-decoration: none;
    padding: 12px 24px;
  }
  .main-logo {
    cursor: pointer;
  }
  #mobile-navbar-main hr {
    border: 1px solid #fff;
    margin: 24px;
  }
</style>