<template>
  <div id="html-dom-form-framework">
    <div class="container" id="step-0" v-if="this.currentStep === 1">
      <div class="introductory">
        {{ $t("eventSessionRegistration.introductory1") }}
        <br /><br />
        {{ $t("eventSessionRegistration.introductory2") }}
        <br />
        {{ $t("eventSessionRegistration.introductory3") }}
        <br />
        <ul>
          <li>
            <span v-html="$t('eventSessionRegistration.introductory4')"></span>
          </li>
          <li>
            {{ $t("eventSessionRegistration.introductory5") }}
            <a
              class="link"
              href="https://www.facebook.com/groups/244756710882712"
              >{{ $t("eventSessionRegistration.introductory5_1") }}</a
            >
            {{ $t("eventSessionRegistration.introductory5_2") }}
          </li>
          <li>{{ $t("eventSessionRegistration.introductory6") }}</li>
          <li>
            <span v-html="$t('eventSessionRegistration.introductory7')"></span>
          </li>
          <li>
            <span v-html="$t('eventSessionRegistration.introductory8')"></span>
          </li>
          <li>{{ $t("eventSessionRegistration.introductory9") }}</li>
        </ul>
        <br />
        {{ $t("eventSessionRegistration.introductory10") }}
        <br />
        <ul>
          <li>{{ $t("eventSessionRegistration.introductory11") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory12") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory13") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory14") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory15") }}</li>
        </ul>
      </div>
      <div style="text-align: center">
        <div class="introductory">
          {{ $t("eventSessionRegistration.introductory16") }}
        </div>
        <input type="text" id="code-input" v-model="code" />
      </div>

      <div class="introductory" style="text-align:center;" v-if="codeIsEmpty">
        {{ $t("eventSessionRegistration.errorCodeIsEmpty") }}
      </div>
      <div
        class="introductory"
        style="text-align:center;"
        v-else-if="codeIsInvalid"
      >
        {{ $t("eventSessionRegistration.errorCodeIsInvalid") }}
      </div>
      <div
        class="introductory"
        style="text-align:center;"
        v-else-if="codeIsExpired"
      >
        <p v-html="$t('eventSessionRegistration.errorCodeIsExpired')"></p>
      </div>
      <div
        class="introductory"
        style="text-align:center;"
        v-else-if="eventNotStarted"
      >
        {{ $t("eventSessionRegistration.errorCodeEventNotStarted") }}
      </div>
      <div
        class="introductory"
        style="text-align:center;"
        v-else-if="eventHasEnded"
      >
        {{ $t("eventSessionRegistration.errorCodeEventEnded") }}
      </div>

      <div class="btn-container" style="margin: 38px 0;" @click="verifyCode()">
        <img
          src="../assets/images/btn-yellow.svg"
          class="yellow-btn method-btn"
        />
        <div class="centered  method-btn-text">
          {{ $t("eventSessionRegistration.confirm") }}
        </div>
      </div>
    </div>

    <div
      class="container"
      style="text-align:center"
      id="step-1"
      v-if="this.currentStep === 99"
    >
      <div class="introductory">
        {{ $t("eventSessionRegistration.step1Intro1") }}
        <a
          style="color: #fff;text-decoration: underline"
          target="_blank"
          href="https://esurvey.psy.cuhk.edu.hk/jfe/form/SV_brxcBphRsXEVWqq"
          >{{ $t("eventSessionRegistration.step1Intro2") }}</a
        >
        {{ $t("eventSessionRegistration.step1Intro3") }}
      </div>
      <img style="margin: 24px 0px" src="../assets/images/sample.png" />

      <div class="content" v-if="fileIsEmpty">
        {{ $t("eventSessionRegistration.errorFileIsEmpty") }}
      </div>
      <div class="content" v-else-if="fileIsTooLarge">
        {{ $t("eventSessionRegistration.errorFileIsTooLarge") }}
      </div>
      <div class="content" v-else-if="this.file != null">
        {{ $t("eventSessionRegistration.successUpload") }}
        <div class="content">{{ filename }}</div>
      </div>

      <image-uploader
        :debug="1"
        :maxWidth="512"
        :quality="0.7"
        :autoRotate="true"
        outputFormat="verbose"
        :preview="false"
        :capture="false"
        accept="image/*"
        @input="handleImage"
        id="fileUpload"
        @onUpload="startImageResize"
        style="display:none"
      ></image-uploader>

      <div
        class="btn-container"
        style="margin-top: 38px;"
        @click="chooseFiles()"
      >
        <img
          src="../assets/images/btn-yellow.svg"
          class="yellow-btn method-btn"
        />
        <div class="centered  method-btn-text">
          {{ $t("eventSessionRegistration.upload") }}
        </div>
      </div>
      <div
        class="btn-container"
        style="margin: 38px 0;"
        @click="submitScreenCap()"
      >
        <img
          src="../assets/images/btn-yellow.svg"
          class="yellow-btn method-btn"
        />
        <div class="centered  method-btn-text">
          {{ $t("eventSessionRegistration.confirm") }}
        </div>
      </div>
    </div>

    <div
      class="container"
      style="text-align:center;"
      id="step-2"
      v-if="this.currentStep === 2"
    >
      <div style="text-align: center; margin-top: 24px">
        <a
          :href="`https://www.museumkol.hk/${$i18n.locale}/events`"
          target="_blank"
        >
          <div class="btn-container">
            <img
              src="../assets/images/btn-yellow.svg"
              class="yellow-btn method-btn"
            />
            <div class="centered  method-btn-text">
              {{ $t("eventSessionRegistration.details") }}
            </div>
          </div>
        </a>
      </div>
      <div class="introductory">
        {{ $t("eventSessionRegistration.step2Intro") }}
      </div>

      <div class="section-header">
        <div class="title">
          {{ $t("event.sectionTitle1") }}
        </div>
      </div>
      <div class="section">
        <!--Hardcode core event 1-->
        <div class="event-record">
          <div>
            <div class="sub-header">
              <div class="title">
                {{ $t("eventSessionRegistration.event1Title") }}
              </div>
            </div>
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-3">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.time") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.status") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.selection") }}
              </div>
            </div>
          </div>
          <div class="row content" style="margin-top: 12px;">
            <div class="col-12 item">
              {{ $t("eventSessionRegistration.event1Description") }}
            </div>
          </div>
          <div class="content remarks">
            <div>{{ $t("eventSessionRegistration.remarks") }}</div>
            <div>
              {{ $t("eventSessionRegistration.event1Remarks1") }}
            </div>
            <div>
              {{ $t("eventSessionRegistration.event1Remarks2") }}
            </div>
          </div>
        </div>
        <!--Hardcode core event 2-->
        <div class="event-record">
          <div>
            <div class="sub-header">
              <div class="title">
                {{ $t("eventSessionRegistration.event2Title") }}
              </div>
            </div>
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-3">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.time") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.status") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.selection") }}
              </div>
            </div>
          </div>
          <div class="row content" style="margin-top: 12px;">
            <div class="col-12 item">
              {{ $t("eventSessionRegistration.event2Description") }}
            </div>
          </div>
          <div class="content remarks">
            <div>{{ $t("eventSessionRegistration.remarks") }}</div>
            <div>
              {{ $t("eventSessionRegistration.event2Remarks1") }}
            </div>
            <div>
              {{ $t("eventSessionRegistration.event2Remarks2") }}
            </div>
          </div>
        </div>
        <!--End hardcode core event-->

        <div
          class="event-record"
          :key="event.id"
          v-for="(event, index) in events.filter((item) => item.isCoreActivity)"
        >
          <div
            v-if="
              index === 0 ||
                event.eventRefId !==
                  events.filter((item) => item.isCoreActivity)[index - 1]
                    .eventRefId
            "
          >
            <div class="sub-header">
              <div class="title">
                {{ event.title }}
              </div>
            </div>
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-3">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.time") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.status") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.selection") }}
              </div>
            </div>
          </div>
          <div class="row content" style="margin-top: 12px;">
            <div class="col-3 item">
              {{ event.date }}
            </div>
            <div class="col-3 item">
              <span v-if="event.startTime == 'NULL' || event.endTime == 'NULL'">
                {{ $t("eventSessionRegistration.pending") }}
              </span>
              <span v-else>
                {{
                  event.startTime
                    .trim()
                    .substring(0, event.startTime.trim().length - 3)
                }}
                -
                {{
                  event.endTime
                    .trim()
                    .substring(0, event.startTime.trim().length - 3)
                }}
              </span>
            </div>
            <div class="col-3 item">
              <span v-if="event.capacity > event.applied">
                {{ $t("eventSessionRegistration.available") }}
              </span>
              <span v-else>
                {{ $t("eventSessionRegistration.full") }}
              </span>
            </div>
            <div class="col-3 item">
              <input
                type="checkbox"
                :eventId="event.eventRefId"
                :disabled="
                  event.capacity <= event.applied ||
                    events
                      .filter(
                        (item) =>
                          selectedSessionId.includes(item.id) &&
                          item.id !== event.id
                      )
                      .map((item) => item.eventRefId)
                      .includes(event.eventRefId) ||
                    events
                      .filter((item) => item.isCoreActivity)
                      .filter((item) => selectedSessionId.includes(item.id))
                      .length > 4
                "
                style="transform : scale(1.8);"
                v-model="selectedSessionId"
                :value="event.id"
                @change="
                  validateToShowAlert($event, event.date, event.categoryCode)
                "
              />
            </div>
          </div>
          <div
            class="content remarks"
            v-if="
              event.remarks != null &&
                ((index <
                  events.filter((item) => item.isCoreActivity).length - 1 &&
                  event.eventRefId !==
                    events.filter((item) => item.isCoreActivity)[index + 1]
                      .eventRefId) ||
                  index + 1 ==
                    events.filter((item) => item.isCoreActivity).length)
            "
          >
            <div v-html="event.remarks"></div>
          </div>
        </div>
      </div>

      <div class="section-header">
        <div class="title">
          {{ $t("event.sectionTitle2") }}
        </div>
      </div>
      <div class="section">
        <div
          class="event-record"
          :key="event.id"
          v-for="(event, index) in events.filter(
            (item) => !item.isCoreActivity
          )"
        >
          <div
            v-if="
              index === 0 ||
                event.eventRefId !==
                  events.filter((item) => !item.isCoreActivity)[index - 1]
                    .eventRefId
            "
          >
            <div class="sub-header">
              <div class="title">
                {{ event.title }}
              </div>
            </div>
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-3">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.time") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.status") }}
              </div>
              <div class="col-3">
                {{ $t("eventSessionRegistration.selection") }}
              </div>
            </div>
          </div>
          <div class="row content" style="margin-top: 12px;">
            <div class="col-3 item">
              {{ event.date }}
            </div>
            <div class="col-3 item">
              <span v-if="event.startTime == 'NULL' || event.endTime == 'NULL'">
                {{ $t("eventSessionRegistration.pending") }}
              </span>
              <span v-else>
                {{
                  event.startTime
                    .trim()
                    .substring(0, event.startTime.trim().length - 3)
                }}
                -
                {{
                  event.endTime
                    .trim()
                    .substring(0, event.startTime.trim().length - 3)
                }}
              </span>
            </div>
            <div class="col-3 item">
              <span v-if="event.capacity > event.applied">
                {{ $t("eventSessionRegistration.available") }}
              </span>
              <span v-else>
                {{ $t("eventSessionRegistration.full") }}
              </span>
            </div>
            <div class="col-3 item">
              <input
                type="checkbox"
                :eventId="event.eventRefId"
                :disabled="
                  event.capacity <= event.applied ||
                    events
                      .filter(
                        (item) =>
                          selectedSessionId.includes(item.id) &&
                          item.id !== event.id
                      )
                      .map((item) => item.eventRefId)
                      .includes(event.eventRefId) ||
                    (events
                      .filter((item) => !item.isCoreActivity)
                      .filter((item) => selectedSessionId.includes(item.id))
                      .length >= 4 &&
                      !selectedSessionId.includes(event.id))
                "
                style="transform : scale(1.8);"
                v-model="selectedSessionId"
                :value="event.id"
              />
            </div>
          </div>
          <div
            class="content remarks"
            v-if="
              event.remarks != null &&
                ((index <
                  events.filter((item) => !item.isCoreActivity).length - 1 &&
                  event.eventRefId !==
                    events.filter((item) => !item.isCoreActivity)[index + 1]
                      .eventRefId) ||
                  index + 1 ==
                    events.filter((item) => !item.isCoreActivity).length)
            "
          >
            <div v-html="event.remarks"></div>
          </div>
        </div>
      </div>

      <div class="content" style="text-align: left;">
        <ul>
          <li>{{ $t("eventSessionRegistration.remark1") }}</li>

          <li>{{ $t("eventSessionRegistration.remark2") }}</li>

          <li>{{ $t("eventSessionRegistration.remark3") }}</li>

          <li>{{ $t("eventSessionRegistration.remark4") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory17") }}</li>
          <li>{{ $t("eventSessionRegistration.introductory18") }}</li>
        </ul>
      </div>

      <div style="text-align: center; margin: 100px 0">
        <div class="content error-msg" v-if="coreIsEmpty">
          {{ $t("eventSessionRegistration.errorCoreIsEmpty") }}
        </div>
        <div class="content error-msg" v-else-if="electiveIsEmpty">
          {{ $t("eventSessionRegistration.errorElectiveIsEmpty") }}
        </div>

        <div
          class="btn-container"
          @click="validateForm()"
          style="margin-top: 24px"
        >
          <img
            src="../assets/images/btn-yellow.svg"
            class="yellow-btn method-btn"
          />
          <div class="centered  method-btn-text">
            {{ $t("eventSessionRegistration.submit") }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      style="text-align:center;"
      id="step-3"
      v-if="this.currentStep === 3"
    >
      <div class="section-header">
        <div class="introductory">
          {{ $t("eventSessionRegistration.step3Intro") }}
        </div>
        <div class="content">
          {{ $t("eventSessionRegistration.step3Warning") }}
        </div>
      </div>

      <div class="section-header">
        <div class="title">
          {{ $t("event.sectionTitle1") }}
        </div>
      </div>
      <div class="section">
        <!--Hardcode core event 1-->
        <div>
          <div class="sub-header">
            <div class="title">
              {{ $t("eventSessionRegistration.event1Title") }}
            </div>
          </div>
          <div id="preview-item">
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-6">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-6">
                {{ $t("eventSessionRegistration.time") }}
              </div>
            </div>
            <div class="row content" style="margin-top: 12px;">
              <div class="col-12 item">
                {{ $t("eventSessionRegistration.event1Description") }}
              </div>
            </div>
          </div>
        </div>
        <!--end Hardcode core event 1-->
        <br />
        <!--Hardcode core event 2-->
        <div>
          <div class="sub-header">
            <div class="title">
              {{ $t("eventSessionRegistration.event2Title") }}
            </div>
          </div>
          <div id="preview-item">
            <div
              class="row content"
              style="margin-top: 12px;border-bottom: 1px solid #fff"
            >
              <div class="col-6">
                {{ $t("eventSessionRegistration.date") }}
              </div>
              <div class="col-6">
                {{ $t("eventSessionRegistration.time") }}
              </div>
            </div>
            <div class="row content" style="margin-top: 12px;">
              <div class="col-12 item">
                {{ $t("eventSessionRegistration.event2Description") }}
              </div>
            </div>
          </div>
        </div>
        <!--end Hardcode core event 2-->
        <div
          class="event-record"
          :key="event.id"
          v-for="event in events.filter((item) => item.isCoreActivity)"
        >
          <div v-if="selectedSessionId.includes(event.id)">
            <div class="sub-header">
              <div class="title">
                {{ event.title }}
              </div>
            </div>
            <div id="preview-item">
              <div
                class="row content"
                style="margin-top: 12px;border-bottom: 1px solid #fff"
              >
                <div class="col-6">
                  {{ $t("eventSessionRegistration.date") }}
                </div>
                <div class="col-6">
                  {{ $t("eventSessionRegistration.time") }}
                </div>
              </div>
              <div class="row content" style="margin-top: 12px;">
                <div class="col-6 item">
                  {{ event.date }}
                </div>
                <div class="col-6 item">
                  <span
                    v-if="event.startTime == 'NULL' || event.endTime == 'NULL'"
                  >
                    {{ $t("eventSessionRegistration.pending") }}
                  </span>
                  <span v-else>
                    {{
                      event.startTime
                        .trim()
                        .substring(0, event.startTime.trim().length - 3)
                    }}
                    -
                    {{
                      event.endTime
                        .trim()
                        .substring(0, event.startTime.trim().length - 3)
                    }}
                  </span>
                </div>
              </div>
              <div class="content remarks">
                <div
                  v-html="event.remarks"
                  v-if="event.categoryCode === 'core-2'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-header">
        <div class="title">
          {{ $t("event.sectionTitle2") }}
        </div>
      </div>
      <div class="section">
        <div
          class="event-record"
          :key="event.id"
          v-for="event in events.filter((item) => !item.isCoreActivity)"
        >
          <div v-if="selectedSessionId.includes(event.id)">
            <div class="sub-header">
              <div class="title">
                {{ event.title }}
              </div>
            </div>
            <div id="preview-item">
              <div
                class="row content"
                style="margin-top: 12px;border-bottom: 1px solid #fff"
              >
                <div class="col-6">
                  {{ $t("eventSessionRegistration.date") }}
                </div>
                <div class="col-6">
                  {{ $t("eventSessionRegistration.time") }}
                </div>
              </div>
              <div class="row content" style="margin-top: 12px;">
                <div class="col-6 item">
                  {{ event.date }}
                </div>
                <div class="col-6 item">
                  <span
                    v-if="event.startTime == 'NULL' || event.endTime == 'NULL'"
                  >
                    {{ $t("eventSessionRegistration.pending") }}
                  </span>
                  <span v-else>
                    {{
                      event.startTime
                        .trim()
                        .substring(0, event.startTime.trim().length - 3)
                    }}
                    -
                    {{
                      event.endTime
                        .trim()
                        .substring(0, event.startTime.trim().length - 3)
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-container" style="margin: 32px 0;" @click="editForm()">
        <img
          src="../assets/images/btn-yellow.svg"
          class="yellow-btn method-btn"
        />
        <div class="centered  method-btn-text">
          {{ $t("eventSessionRegistration.edit") }}
        </div>
      </div>

      <div class="btn-container" style="margin: 32px 0;" @click="submitForm()">
        <img
          src="../assets/images/btn-yellow.svg"
          class="yellow-btn method-btn"
        />
        <div class="centered  method-btn-text">
          {{ $t("eventSessionRegistration.confirm") }}
        </div>
      </div>
    </div>

    <div
      class="container"
      style="text-align:center;"
      id="step-4"
      v-if="this.currentStep === 4"
    >
      <div class="section-header">
        <div class="introductory">
          {{ $t("eventSessionRegistration.completeTitle") }}
        </div>
      </div>
      <div class="section-header">
        <div class="title">
          {{ $t("eventSessionRegistration.completeText") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "vue-image-upload-resize";

export default {
  name: "EventSessionRegistration",
  title: "小小博物館 KOL",
  data() {
    return {
      currentStep: 1,
      file: null,
      code: "",
      accessToken: "",
      events: [],
      originalFilesize: 0,
      codeIsEmpty: false,
      codeIsInvalid: false,
      codeIsExpired: false,
      eventNotStarted: false,
      eventHasEnded: false,
      fileIsEmpty: false,
      fileIsTooLarge: false,
      coreIsEmpty: false,
      electiveIsEmpty: false,
      selectedSessionId: [],
      filename: "",
      submitEnable: true,
    };
  },
  components: { ImageUploader },
  methods: {
    verifyCode: function() {
      let vue = this;

      // Check if code is empty
      if (vue.code == null || vue.code == "") {
        vue.codeIsEmpty = true;
        return;
      }

      // // debug
      // this.currentStep = 2;

      // debug
      vue.codeIsEmpty = false;
      vue.codeIsInvalid = false;
      vue.codeIsExpired = false;
      vue.eventNotStarted = false;
      vue.eventHasEnded = false;

      // AJAX request to verify code
      axios({
        method: "get",
        url: `${
          vue.$CMS_API_URL
        }/events_backend/event-app-handshake.php?code=${vue.code.trim()}`,
      })
        .then((response) => {
          const data = response.data;
          if (data.status == "success" && data.access_token) {
            this.getEventLists();
            vue.accessToken = data.access_token;
            vue.currentStep++;
          }
        })
        .catch(function(error) {
          // console.log(error.response.data);
          const data = error.response.data;
          if (data.message === "Event not started") {
            vue.eventNotStarted = true;
          } else if (data.message === "Event ended") {
            vue.eventHasEnded = true;
          } else if (data.message === "Used code") {
            vue.codeIsExpired = true;
          } else {
            vue.codeIsInvalid = true;
          }
        });
    },
    submitScreenCap: function() {
      // console.log(this.file);

      // File empty check
      if (this.file == null) {
        this.fileIsEmpty = true;
        return;
      } else {
        this.fileIsEmpty = false;
      }

      this.getEventLists(); // Load event List
      document.getElementById("app").scrollIntoView();
      this.currentStep++; // Move to next step
    },
    getEventLists: function() {
      var vue = this;

      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      // AJAX request to get events
      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/events_backend/event-app-event-list.php?token=${this.accessToken}&lang=${locale}`,
      })
        .then((response) => {
          // console.log(response.data);

          const data = response.data;
          if (data.status == "success" && Array.isArray(data.data)) {
            vue.events = data.data
              .filter((item) => item.category !== "core-1")
              .map((item) => {
                let category = "-";
                const categoryCode = item.category;
                let isCoreActivity = false;
                switch (categoryCode) {
                  case "core-2":
                    category = vue.$t("event.core2");
                    isCoreActivity = true;
                    break;
                  case "core-3":
                    category = vue.$t("event.core3");
                    isCoreActivity = true;
                    break;
                  case "elective-1":
                    category = vue.$t("event.elective1");
                    break;
                  case "elective-2":
                    category = vue.$t("event.elective2");
                    break;
                  case "elective-3":
                    category = vue.$t("event.elective3");
                    break;
                  case "elective-4":
                    category = vue.$t("event.elective4");
                    break;
                }

                return {
                  id: item.id,
                  eventRefId: item.event_ref_id,
                  date: item.a_date,
                  endTime: item.a_ed_time,
                  startTime: item.a_st_time,
                  capacity: parseInt(item.capacity),
                  applied: parseInt(item.applied),
                  category: category,
                  categoryCode: item.category,
                  title: item.title,
                  isCoreActivity,
                  remarks: item.remarks,
                };
              })
              .sort((a, b) => b.isCoreActivity - a.isCoreActivity);

            // console.log(vue.events);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    validateForm: function() {
      this.coreIsEmpty = false;
      this.electiveIsEmpty = false;

      // Initialize selected session to be a Set
      let setOfSelected = new Set();
      this.events
        .filter((item) => this.selectedSessionId.includes(item.id))
        .map((item) => setOfSelected.add(item.eventRefId));

      // console.log("setOfSelected", setOfSelected);

      // Validate if Core Activities selected
      let setOfCore = new Set();
      this.events
        .filter((item) => item.isCoreActivity)
        .map((item) => setOfCore.add(item.eventRefId));
      // console.log("setOfCore",setOfCore);
      if (this.intersection(setOfSelected, setOfCore).size < setOfCore.size) {
        this.coreIsEmpty = true;
        return;
      }

      // Validate if Elective Activities selected (at least 2)
      var setOfElective = new Set();
      this.events
        .filter((item) => !item.isCoreActivity)
        .map((item) => setOfElective.add(item.eventRefId));
      // console.log("setOfElective", setOfElective);
      if (this.intersection(setOfSelected, setOfElective).size < 2) {
        this.electiveIsEmpty = true;
        return;
      }
      document.getElementById("app").scrollIntoView();
      this.currentStep++;
    },
    submitForm: function() {
      const vue = this;

      if (vue.submitEnable == false) return;

      vue.submitEnable = false;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      // console.log(this.selectedSessionId);
      axios
        .post(`${vue.$CMS_API_URL}/events_backend/event-app-event-apply.php`, {
          data: vue.selectedSessionId,
          img: vue.file || "unused",
          token: vue.accessToken,
          lang: locale,
          password: vue.code,
        })
        .then(() => {
          // console.log(response);
          document.getElementById("app").scrollIntoView();
          vue.submitEnable = true;
          this.currentStep++;
        })
        .catch(function(error) {
          console.log(error);
          this.currentStep -= 2;
          vue.submitEnable = true;
          alert(vue.$t("eventSessionRegistration.errorFailSubmit"));
        });
    },
    chooseFiles: function() {
      document.getElementById("fileUpload").click();
    },
    handleImage: function(obj) {
      const imageFile = obj.dataUrl;
      this.file = null; // reset uploaded file

      // check for original file size
      if (this.originalFilesize > 1.5) {
        this.fileIsTooLarge = true;
        return;
      }

      this.fileIsTooLarge = false;
      this.fileIsEmpty = false;
      this.file = imageFile;
      this.filename = obj.info.name;
    },
    startImageResize: function() {
      const file = document.getElementById("fileUpload").files[0];
      // File size check
      const filesize = (file.size / 1024 / 1024).toFixed(4); // in MB
      this.originalFilesize = filesize;
    },
    editForm: function() {
      this.currentStep--;
    },
    intersection: function(setA, setB) {
      let _intersection = new Set();
      for (let elem of setB) {
        if (setA.has(elem)) {
          _intersection.add(elem);
        }
      }
      return _intersection;
    },
    validateToShowAlert: function(event, date, categoryCode) {
      var vue = this;
      if (event.target.checked == false) return;
      if (categoryCode === "core-2") {
        if (date === "2022-09-04") {
          alert(vue.$t("eventSessionRegistration.reminderAlert1"));
        } else if (date === "2022-09-18") {
          alert(vue.$t("eventSessionRegistration.reminderAlert2"));
        }
      }
    },
  },
  watch: {
    "$i18n.locale": function() {
      this.getEventLists();
    },
  },
};
</script>

<style scoped>
.introductory {
  font-size: 24px;
  margin-top: 48px;
}
@media (max-width: 992px) {
  .introductory {
    margin-top: 72px;
    font-size: 20px;
  }
}
.content {
  text-align: center;
  font-size: 20px;
}
@media (max-width: 992px) {
  .content {
    font-size: 18px;
  }
}
#code-input {
  width: 40%;
  min-width: 280px;
  height: 48px;
  margin-top: 24px;
  font-size: 24px;
  padding: 0px 12px;
}
.btn-container {
  cursor: pointer;
}
.section-header .title {
  padding: 12px;
  background-color: #ea9852;
  margin-bottom: 42px;
  margin-top: 32px;
}
.section {
  padding: 0 10%;
}
.sub-header .title {
  border: 1px solid #fff;
  background-color: unset;
}
.remarks {
  margin: 24px 0;
}
@media (min-width: 992px) {
  .remarks,
  #preview-item {
    margin-bottom: 100px;
  }
}
.content .item {
  word-wrap: break-word;
  font-size: 18px;
}
@media (max-width: 576px) {
  .content .item {
    font-size: 14px;
  }
}
.event-record {
  margin-bottom: 36px;
}
#preview-item {
  padding: 0 24%;
}
@media (max-width: 996px) {
  #preview-item {
    padding: 0 12%;
  }
}
a.link {
  color: #fff;
  text-decoration: underline;
}

.error-msg {
  border-width: 4px;
  border-color: #f6fc28;
  border-style: solid;
  display: inline-block;
  padding: 8px 24px;
  border-radius: 14px;
}
</style>

<style>
a.i18n-link {
  color: #fff;
  text-decoration: underline;
}
</style>
